import React from "react";
import { useEffect, useState, useRef } from "react";
import styles from "../Components/css/dailyworkshop.module.css";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const DailyTrackerWorkshop = () => {
  const [stats, setstats] = useState("Ongoing");
  const [dailyTracker, setDailyTracker] = useState(2);
  const [checklistdata, setchecklistdata] = useState([]);
  const presentdate = new Date();
  const [finallength1, setfinallength1] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const { id } = useParams;
  const [onsearchTerm, setonSearchTerm] = useState("");
  const [researchTerm, setreSearchTerm] = useState("");
  const [clsearchTerm,setClsearchTerm] = useState("");
  const [upcomingdetails, setupcomingdetails] = useState([]);
  const [upcomingdetailsfil, setupcomingdetailsfil] = useState([]);
  const [ongoingdetails, setongoingdetails] = useState([]);
  const [ongoingdetailsfil, setongoingdetailsfil] = useState([]);
  const [readyForDispatchdetails, setReadyForDispatchdetails] = useState([]);
  const [readyForDispatchdetailsfil, setReadyForDispatchdetailsfil] = useState([]);
  const [completedDetails, setCompletedDetails] = useState([]);
  const [completedDetailsfil,setCompletedDetailsfil] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [workshopdetails, setworkshopdetails] = useState([]);
  const [teamdeployed, setteamdeployed] = useState([]);
  const { id } = useParams();
  const [filteredtime, setfilteredtime] = useState([]);
  const [sum, setsum] = useState('');
  const [sum2, setsum2] = useState(1);
  const [man, setman] = useState([]);
  const [uniquedates, setuniquedates] = useState([]);
  const [filtereddatee, setfiltereddatee] = useState([]);
  const [datearray, setdatearray] = useState([]); // Add this line
  const [super1, setsuper1] = useState([]);
  const [tech, settech] = useState([]);
  const [train, settrain] = useState([]);

  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",
    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    DmccFze:"",
    FzeDmccPo:"",
  });

  const navigate = useNavigate();
  const dailyTrackerTab = (tabNumber) => {
    setDailyTracker(tabNumber);
    if (tabNumber === 1) {
      navigate('/dailytracker');
    } else if (tabNumber === 2) {
      navigate('/dailytrackerworkshop');
    }
  };



  // useEffect (() => {
  //   const fetchDetails = async () => {
  //     const res = await axios.get(
  //       try {
  //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
  //     );
  //     const resz = res.data;
  //     setworkshopdetails(resz);
  //       } catch (error) {
  //     console.log(workshopdetails);
  //       }
  //   };
  //   fetchDetails();
  //   console.log(workshopdetails);
  // }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        DmccFze:filter[0]?.Dmcc_fze,
        FzeDmccPo:filter[0]?.Fze_dmcc_po,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    async function fetchRigggDetails() {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/timesheetwork_data"
        );

        console.log("Response:", response.data); // Log the response to check if it's received correctly

        const filteredData = response.data.data;
        setfilteredtime(response.data.data);


      } catch (error) {
        console.error("Error:", error); // Log any errors
      }
    }

    fetchRigggDetails();
  },[]);







  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const handleSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setSearchTerm(lowerCaseValue);

    const filtered = upcomingdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setupcomingdetailsfil(filtered);
  };
  const handleonSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setonSearchTerm(lowerCaseValue);

    const filtered = ongoingdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setongoingdetailsfil(filtered);
  };
  const handlereSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setreSearchTerm(lowerCaseValue);

    const filtered = readyForDispatchdetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setReadyForDispatchdetailsfil(filtered);
  };

  const handleclSearchName = (e) => {
    const { value } = e.target;
    const lowerCaseValue = value.toLowerCase();
    setClsearchTerm(lowerCaseValue);

    const filtered = completedDetails.filter((el, index) => {
      const name = el.Rig_Name ? el.Rig_Name.toLowerCase() : "";

      if (name.includes(lowerCaseValue)) {
        return true;
      }
      return false;
    });

    setCompletedDetailsfil(filtered);
  };

  const handlestatChange = (event) => {
    const selectedstat = event.target.value;
    setstats(selectedstat);
  };





  {
    filteredProjects.map((el) => {
      const estimatedDate = new Date(el.Estimated_Date_Of_Commencement);
      const completionDate = new Date(el.Estimated_Project_Completion_Month);
      const presentDate = new Date();
      const inDays = Math.floor(
        (presentDate - estimatedDate) / (1000 * 60 * 60 * 24)
      );
      console.log(filteredProjects);

      let daysArrivedSince = 0;
      if (el.First_Arrival) {
        const [day, month, year] = el.First_Arrival.split("/");
        const estimatedDate2 = new Date(`${month}/${day}/${year}`);
        estimatedDate2.setHours(0, 0, 0, 0); // set time to midnight
        const presentDate2 = new Date();
        presentDate2.setHours(0, 0, 0, 0); // set time to midnight
        daysArrivedSince = Math.floor(
          (presentDate2 - estimatedDate2) / (1000 * 60 * 60 * 24)
        );
      }

      if (daysArrivedSince < 0) {
        daysArrivedSince = "NA";
      }

    })
  }


  useEffect(() => {
    if (filteredProjects.length > 0) {
      const presentDate = new Date();

      const upcomingProjects = filteredProjects.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          return presentDate < estimatedDate;
        }
        return false;
      });

      const ongoingProjects = filteredProjects.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          return presentDate > estimatedDate && check[0]?.Item30A !== "Completed";
        }
        return false;
      });

      const readyForDispatchProjects = filteredProjects.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          let count = 0;
          for (let i = 1; i <= 40; i++) {
            const propertyName = "Item" + i;
            if (check[0]?.[propertyName] === "Completed") {
              count++;
            }
          }
          return (
            presentDate > estimatedDate &&
            check[0]?.Item30A === "Completed" && check[0]?.Item42A !== "Completed" 
          );
        }
        return false;
      });

      const completedProjects = filteredProjects.filter((el) => {
        if (el.Estimated_Date_Of_Commencement) {
          const parts = el.Estimated_Date_Of_Commencement.split("/");
          const day = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const year = parseInt(parts[2]);
          const estimatedDate = new Date(year, month, day);
          const check = checklistdata.filter((elm) => elm.ID_No === el.ID_No);
          let count = 0;
          for (let i = 1; i <= 40; i++) {
            const propertyName = "Item" + i;
            if (check[0]?.[propertyName] === "Completed") {
              count++;
            }
          }
          return (
            presentDate > estimatedDate &&
            check[0]?.Item30A === "Completed" && check[0]?.Item42A === "Completed" 
            
          );
        }
        return false;
      });

      setupcomingdetails(upcomingProjects);
      setongoingdetails(ongoingProjects);
      setReadyForDispatchdetailsfil(readyForDispatchProjects);
      setCompletedDetailsfil(completedProjects);
    }
  }, [filteredProjects, checklistdata]);


  const handleerror = () => {
    console.log("help");
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
        );
        setProjects(response.data);
        setFilteredProjects(response.data.data);

        const res = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshopproject-checklistgetdetails"
        );
        setchecklistdata(res.data.data);
        console.log(checklistdata);

        const res1 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/techworkshop_data"
        );
        setteamdeployed(res1.data.data);
        const res2 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supervisor_customer_names/workshop"
        );
        setsuper1(res2.data.data);
        const res3 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/technician_customer_names/workshop"
        );
        settech(res3.data.data);
        const res4 = await axios.get(
          "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/trainee_customer_names/workshop"
        );
        settrain(res4.data.data);
        const finallength = res2.data.data.length + res3.data.data.length + res4.data.data.length;
        setfinallength1(finallength);
        console.log(teamdeployed);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);


  const handleexceldwn =()=>{
    const form = document.getElementById(stats);
    const table = form.querySelector("table");
    const rows = Array.from(table.querySelectorAll("tr"));

    const data = rows.map((row) =>
      Array.from(row.querySelectorAll("td, th")).map((cell) => cell.textContent)
    );

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.write to generate the Excel file as an ArrayBuffer
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Use FileSaver.js to save the file
    saveAs(blob, "table_data.xlsx");
  }

  return (
    <>
      <div className={styles.dropdiv}>
        {dailyTracker === 2 && (
          <>
            <div
              style={{
                borderBottomLeftRadius: "10px",
                borderTopLeftRadius: "10px",
                marginLeft: "20px"
              }}
              className={
                dailyTracker === 1 ? styles.aryaTabs1 : styles.aryaTabs2
              }
              onClick={() => dailyTrackerTab(1)}>
              Offshore
            </div>
            <div
              style={{
                borderBottomRightRadius: "10px",
                borderTopRightRadius: "10px",
                marginLeft: "-16px",
              }}
              className={
                dailyTracker === 2 ? styles.aryaTabs1 : styles.aryaTabs2
              }
              onClick={() => dailyTrackerTab(2)}>
              Workshop
            </div>
            <label className={styles.dropdiv2} > Select Project Status:</label>
            <select className={styles.dropdiv3} value={stats} onChange={handlestatChange} id="svgdwn" style={{ cursor: "pointer" }}>
              <option value="Ongoing" className={styles.drop}>Ongoing</option>
              <option value="Ready_For_Dispatch" className={styles.drop}>Ready for Dispatch</option>
              <option value="Upcoming" className={styles.drop}>Upcoming</option>
              <option value="Completed"  className={styles.drop}>Completed</option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                marginLeft: "-50px",
                cursor: "pointer"
              }}
              onClick={() => {
                document.getElementById("svgdwn").click();
              }}

            >
              <path
                d="M17.92 8.18018H11.69H6.07999C5.11999 8.18018 4.63999 9.34018 5.31999 10.0202L10.5 15.2002C11.33 16.0302 12.68 16.0302 13.51 15.2002L15.48 13.2302L18.69 10.0202C19.36 9.34018 18.88 8.18018 17.92 8.18018Z"
                fill="#394759"
              />
            </svg>
          </>
        )}

        {(stats === "Upcoming" && dailyTracker === 2) && <div className={styles.searchContainer}>
          {/* <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "50%", marginTop: "-75px" }}>
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg> */}
        </div>
        }
        {(stats === "Ongoing" && dailyTracker === 2) && <div className={styles.searchContainer}>
          {/* <input
            type="text"
            placeholder="Search by name"
            value={onsearchTerm}
            onChange={handleonSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "50%", marginTop: "-75px" }}>
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg> */}
        </div>
        }
        {(stats === "Ready_For_Dispatch" && dailyTracker === 2) && <div className={styles.searchContainer}>
          {/* <input
            type="text"
            placeholder="Search by name"
            value={researchTerm}
            onChange={handlereSearchName}
            className={styles.searchBox}
          />
          <svg
            className={styles.searchIconFilter}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "50%", marginTop: "-75px" }}>
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#BCBCBC"
            />
            <path
              d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
              fill="#BCBCBC"
            />
          </svg> */}
        </div>
        }
 {<svg width="40" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleexceldwn}  className={styles.btnfilter3} >
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 5.28595 -1.19209e-07 2.92893 1.46447 1.46447C2.92893 -1.19209e-07 5.28595 0 10 0C14.714 0 17.0711 -1.19209e-07 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C-1.19209e-07 17.0711 0 14.714 0 10ZM10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V10.1893L12.4697 8.4697C12.7626 8.1768 13.2374 8.1768 13.5303 8.4697C13.8232 8.7626 13.8232 9.2374 13.5303 9.5303L10.5303 12.5303C10.3897 12.671 10.1989 12.75 10 12.75C9.8011 12.75 9.6103 12.671 9.4697 12.5303L6.46967 9.5303C6.17678 9.2374 6.17678 8.7626 6.46967 8.4697C6.76256 8.1768 7.23744 8.1768 7.53033 8.4697L9.25 10.1893V5C9.25 4.58579 9.5858 4.25 10 4.25ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75H14C14.4142 15.75 14.75 15.4142 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H6Z" fill="#5B6BE1"/>
</svg>}
      </div>
      <div style={{ overflowX: "auto", flex: 1 }}>
        {stats === "Ongoing" && (
          <form id="Ongoing">
          <table className={styles.dailtracwrkshp}>
            <thead>
              <tr>
                <th>S.No</th>
                <th> Scope</th>
                <th> Project Name </th>
                <th>Sales Order No</th>
                <th> Project Description </th>
                <th> Quantity </th>
                <th> Scope Description</th>
                <th> Current Status</th>
                <th> Days Since Arrival</th>
                <th> Costing </th>
                <th> Quote</th>
                <th> PO </th>
                <th>DMCC-FZE Quote</th>
                <th>FZE-DMCC PO</th>
                {/* <th> Spent/Target Manhours</th> */}
                <th> Commencement Date</th>
                <th> Elapsed/Target days</th>
                <th> Team Deployed </th>
                {/* <th> </th> */}
              </tr>
            </thead>
            <tbody>
              {ongoingdetails.map((el, index) => {
                let estimateDateComm = moment(el.Estimated_Date_Of_Commencement, 'DD/MM/YYYY').toDate();
                const todayDate1 = new Date();
                const elapsedTargetdays = Math.floor(
                  (todayDate1 - estimateDateComm) / (1000 * 60 * 60 * 24)
                );
                console.log('el.Estimated_Date_Of_Commencement:', el.Estimated_Date_Of_Commencement);
                console.log('estimateDateComm:', estimateDateComm);
                console.log('todayDate1:', todayDate1);
                console.log('elapsedTargetdays:', elapsedTargetdays);

                let check = checklistdata.filter(

                  (elm) => elm.ID_No === el.ID_No
                );
                let firstArrivalDate = new Date(el.First_Arrival);
                let todayDate = new Date();
                const daysSinceArrival = Math.floor(
                  (todayDate - firstArrivalDate) / (1000 * 60 * 60 * 24)
                );


                const projectKey = el.Project_Name + "-" + el.Scope + "-" + el.Sales_Order_No;
                let sum = 0;

                const projectManhours = filteredtime
                  .filter(elm => elm.Project === projectKey)
                  .map(item => item.Manhours);

                if (projectManhours.length > 0) {
                  sum = projectManhours.reduce((acc, current) => acc + Number(current),0);
                  // Do something with the sum...
                }
                // You can use the sum variable outside of the if-else block
                // since it was declared outside

                // Continue with the rest of your code...



                return (
                  <tr key={el.ID_No}>
                    <td>{index + 1}</td>
                    <td>

                      {el.Scope ? el.Scope : "-"}{" "}

                    </td>
                    <td>
                      <NavLink
                        to={"/project-details-workshop/" + el.ID_No}

                      >
                        {el.Design ? el.Design : "-"} {el.Project_Name ? el.Project_Name : "-"}

                      </NavLink>

                    </td>
                    <td> {el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>

                    <td className={styles.user}style={{ minWidth: '450px', whiteSpace: 'pre-line', padding: "0px", marginTop: "-5px", padding: "-8px" }}>
                      {el.Project_Description
                        ? el.Project_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{el.Quantity ? el.Quantity : "-"}</td>
                    <td style={{ minWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Scope_Description
                        ? el.Scope_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td style={{ minWidth: '300px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                      {el.Current_Status
                        ? el.Current_Status.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>



                    <td>{isNaN(daysSinceArrival) ? "-" : daysSinceArrival}</td>
                    <td style={{
                      backgroundColor:
                        el.Costing_ID === "" ? "#FF6A6A" : "white",
                      color: el.Costing_ID === "" ? "white" : "",
                      whiteSpace: "nowrap",
                    }}>
                      {el.Costing_ID === ""
                        ? "Costing not Submitted"
                        : "Costing Submitted"}
                      {""}
                      {el.Costing_ID === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue2 ? check[0]?.Issue2 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" ,marginLeft:"5px"}}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          el.Quote_No === "" ? "#FF6A6A" : "white",
                        color: el.Quote_No === "" ? "white" : "",
                      }}>
                      {el.Quote_No === "" ? "Quote not sent" : "Quote sent"}{" "}
                      {el.Quote_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue3 ? check[0]?.Issue3 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          el.PO_No === "" ? "#FF6A6A" : "white",
                        color: el.PO_No === "" ? "white" : "black",
                      }}>
                      {el.PO_No === "" ? "PO not received" : "PO received"}{" "}
                      {el.PO_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue3 ? check[0]?.Issue3 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}
                    </td>
                    <td>{el.Dmcc_fze ? el.Dmcc_fze : "-"}</td>
                    <td>{el.Fze_dmcc_po ? el.Fze_dmcc_po : "-"}</td>
                    {/* <td>{sum !== null ? `${sum}/${el.Target_Manhours || "-"}` : "-"}</td> */}
                    <td>
                      {el.Estimated_Date_Of_Commencement
                        ? el.Estimated_Date_Of_Commencement
                        : "-"}
                    </td>
                    <td>
                      {isNaN(elapsedTargetdays)
                        ? "Invalid Date"
                        : `${elapsedTargetdays}/${el.Target_Days_For_Completion
                          ? el.Target_Days_For_Completion
                          : "-"
                        }`}
                    </td>
                    <td>
                      {el.Supervisors ? el.Supervisors : ""}
                      {el.Supervisors && el.Technicians ? "," : ""}
                      {el.Technicians ? el.Technicians : ""}
                      {(el.Supervisors || el.Technicians) && el.Trainees ? "," : ""}
                      {el.Trainees ? el.Trainees : ""}
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
          </form>
        )}
      </div>
      <div style={{ overflowX: "auto", flex: 1 }}>
        {stats === "Ready_For_Dispatch" && (
          <form id="Ready_For_Dispatch">
          <table className={styles.dailtracwrkshp}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Project Name</th>
                <th>Sales Order No</th>
                <th>Project Description</th>
                <th>Quantity</th>
                <th>Scope Description</th>
                <th>Current Status</th>
                <th>Days Since Arrival</th>
                <th>PO</th>
                <th>Final Report Status</th>
                <th>Invoice Status</th>
              </tr>
            </thead>
            <tbody>
              {readyForDispatchdetailsfil.map((el, index) => {
                let check = checklistdata.filter(
                  (elm) => elm.ID_No === el.ID_No
                );
                let firstArrivalDate = new Date(el.First_Arrival);
                let todayDate = new Date();
                const daysSinceArrival = Math.floor(
                  (todayDate - firstArrivalDate) / (1000 * 60 * 60 * 24)
                );
                return (
                  <tr key={el.ID_No}>
                    <td>{index + 1}</td>
                    <td>
                      <NavLink to={"/project-details-workshop/" + el.ID_No}>
                        {el.Design ? el.Design : "-"} {el.Project_Name ? el.Project_Name : "-"}

                      </NavLink>
                    </td>
                    <td> {el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                    <td style={{ maxWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Project_Description
                        ? el.Project_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{el.Quantity ? el.Quantity : "-"}</td>
                    <td style={{ maxWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Scope_Description
                        ? el.Scope_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td style={{ minWidth: '300px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                      {el.Current_Status
                        ? el.Current_Status.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{isNaN(daysSinceArrival) ? "-" : daysSinceArrival}</td>
                    <td
                      style={{
                        backgroundColor:
                          el.PO_No === "" ? "#FF6A6A" : "white",
                        color: el.PO_No === "" ? "white" : "black",
                      }}>
                      {" "}
                      {el.PO_No === ""
                        ? "PO not received"
                        : "PO received"}{" "}
                      {el.PO_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue4 ? check[0]?.Issue4 : "--"
                            }
                            enterDelay={500} // Adjust the delay time as needed
                            leaveDelay={100}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}{" "}
                    </td>
                    {Array.isArray(check) &&
                      check.map((elm, index) => (
                        <td key={index}>{elm.Item40 ? elm.Item40 : "-"}</td>
                      ))}
                    {Array.isArray(check) &&
                      check.map((elm, index) => (
                        <td key={index}>{elm.Item42 ? elm.Item42 : "-"}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          </form>
        )}
      </div>
      <div style={{ overflowX: "auto", flex: 1 }}>
        {stats === "Upcoming" && (
          <form id="Upcoming">
          <table className={styles.dailtracwrkshp}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Scope</th>
                <th>Project Name</th>
                <th>Sales Order No</th>
                <th>Project Description</th>
                <th>Quantity</th>
                <th>Scope Description</th>
                <th>Current Status</th>
                <th>Spares Status</th>
                <th>Days Since Arrival</th>
                <th>Costing</th>
                <th>Quote</th>
                <th>PO</th>
                <th>DMCC-FZE Quote</th>
                <th>FZE-DMCC PO</th>
                <th>Commencement Date</th>
                <th>Team deployed</th>
              </tr>
            </thead>
            <tbody>
              {upcomingdetails.map((el, index) => {
                let check = checklistdata.filter(
                  (elm) => elm.ID_No === el.ID_No
                );
                let daysSinceArrival = "-";
                if (el.First_Arrival) {
                  const [day, month, year] = el.First_Arrival.split("/");
                  const estimatedDate = new Date(`${month}/${day}/${year}`);
                  const todayDate = new Date();
              
                  if (!isNaN(estimatedDate) && !isNaN(todayDate)) {
                    estimatedDate.setHours(0, 0, 0, 0);
                    todayDate.setHours(0, 0, 0, 0);
                    daysSinceArrival = Math.floor((todayDate - estimatedDate) / (1000 * 60 * 60 * 24));
                  }
                }


                return (
                  <tr key={el.ID_No}>
                    <td>{index + 1}</td>
                    <td>{el.Scope ? el.Scope : "-"} </td>
                    <td>
                      <NavLink to={"/project-details-workshop/" + el.ID_No}>
                        {el.Design ? el.Design : "-"} {el.Project_Name ? el.Project_Name : "-"}
                      </NavLink>
                    </td>
                    <td> {el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                    <td style={{ minWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Project_Description
                        ? el.Project_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{el.Quantity ? el.Quantity : "-"}</td>
                    <td style={{ minWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Scope_Description
                        ? el.Scope_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td style={{ minWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Current_Status
                        ? el.Current_Status.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    {Array.isArray(check) &&
                      check.map((elm, index) => (
                        <td key={index}>{elm.Item17A ? el.Item17A : "-"}</td>
                      ))}
                    <td>{isNaN(daysSinceArrival) ? "-" : daysSinceArrival}</td>
                    <td style={{
                      backgroundColor:
                        el.Costing_ID === "" ? "#FF6A6A" : "white",
                      color: el.Costing_ID === "" ? "white" : "",
                    }}>
                      {el.Costing_ID === ""
                        ? "Costing not Submitted"
                        : "Costing Submitted"}
                      {""}
                      {el.Costing_ID === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue2 ? check[0]?.Issue2 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer",marginLeft:"5px"  }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          el.Quote_No === "" ? "#FF6A6A" : "white",
                        color: el.Quote_No === "" ? "white" : "",
                      }}>
                      {el.Quote_No === "" ? "Quote not sent" : "Quote sent"}{" "}
                      {el.Quote_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue3 ? check[0]?.Issue3 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          el.PO_No === "" ? "#FF6A6A" : "white",
                        color: el.PO_No === "" ? "white" : "black",
                      }}>
                      {" "}
                      {el.PO_No === ""
                        ? "PO not received"
                        : "PO received"}{" "}
                      {el.PO_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue4 ? check[0]?.Issue4 : "--"
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}{" "}
                    </td>
                    <td>{el.Dmcc_fze ? el.Dmcc_fze : "-"}</td>
                    <td>{el.Fze_dmcc_po ? el.Fze_dmcc_po : "-"}</td>
                    <td>
                      {el.Estimated_Date_Of_Commencement
                        ? el.Estimated_Date_Of_Commencement
                        : "-"}
                    </td>
                    {/* {Array.isArray(teamdeployed) &&
                      teamdeployed.map((elm, index) => (
                        <td key={index}>{elm.Item17A ? elm.Item17A : "-"}</td>
                      ))} */}
                    <td>
                      {el.Supervisors ? el.Supervisors : ""}
                      {el.Supervisors && el.Technicians ? "," : ""}
                      {el.Technicians ? el.Technicians : ""}
                      {(el.Supervisors || el.Technicians) && el.Trainees ? "," : ""}
                      {el.Trainees ? el.Trainees : ""}
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
          </form>
        )}
      </div>
      <div style={{ overflowX: "auto", flex: 1 }}>
        {stats ===  "Completed" && (
          <form id= "Completed">
          <table className={styles.dailtracwrkshp}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Project Name</th>
                <th>Sales Order No</th>
                <th>Project Description</th>
                <th>Quantity</th>
                <th>Scope Description</th>
                <th>Current Status</th>
                <th>Days Since Arrival</th>
                <th>PO</th>
                <th>Final Report Status</th>
                <th>Invoice Status</th>
              </tr>
            </thead>
            <tbody>
              {completedDetailsfil.map((el, index) => {
                let check = checklistdata.filter(
                  (elm) => elm.ID_No === el.ID_No
                );
                let firstArrivalDate = new Date(el.First_Arrival);
                let todayDate = new Date();
                const daysSinceArrival = Math.floor(
                  (todayDate - firstArrivalDate) / (1000 * 60 * 60 * 24)
                );
                return (
                  <tr key={el.ID_No}>
                    <td>{index + 1}</td>
                    <td>
                      <NavLink to={"/project-details-workshop/" + el.ID_No}>
                        {el.Design ? el.Design : "-"} {el.Project_Name ? el.Project_Name : "-"}

                      </NavLink>
                    </td>
                    <td> {el.Sales_Order_No ? el.Sales_Order_No : "-"}</td>
                    <td style={{ maxWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Project_Description
                        ? el.Project_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{el.Quantity ? el.Quantity : "-"}</td>
                    <td style={{ maxWidth: '300px', whiteSpace: 'pre-line' }}>
                      {el.Scope_Description
                        ? el.Scope_Description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td style={{ minWidth: '300px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                      {el.Current_Status
                        ? el.Current_Status.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                        : "-"}
                    </td>
                    <td>{isNaN(daysSinceArrival) ? "-" : daysSinceArrival}</td>
                    <td
                      style={{
                        backgroundColor:
                          el.PO_No === "" ? "#FF6A6A" : "white",
                        color: el.PO_No === "" ? "white" : "black",
                      }}>
                      {" "}
                      {el.PO_No === ""
                        ? "PO not received"
                        : "PO received"}{" "}
                      {el.PO_No === "" ? (
                        <>
                          <BootstrapTooltip
                            title={
                              check[0]?.Issue4 ? check[0]?.Issue4 : "--"
                            }
                            enterDelay={500} // Adjust the delay time as needed
                            leaveDelay={100}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              color="white"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </BootstrapTooltip>
                        </>
                      ) : null}{" "}
                    </td>
                    {Array.isArray(check) &&
                      check.map((elm, index) => (
                        <td key={index}>{elm.Item40 ? elm.Item40 : "-"}</td>
                      ))}
                    {Array.isArray(check) &&
                      check.map((elm, index) => (
                        <td key={index}>{elm.Item42 ? elm.Item42 : "-"}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          </form>
        )}
      </div>
    </>
  );
};

export default DailyTrackerWorkshop;