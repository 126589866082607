import React from "react";
import axios from "axios";
import styles from "./css/dispatch.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";
const userEmail = localStorage.getItem('useremail');
if (userEmail) {
  console.log('Found value:', userEmail);
} else {
  console.log('No value found');
}
const Name = localStorage.getItem('Name');
if (Name) {
  console.log('Found value:', Name);
} else {
  console.log('No value found');
}
 function Dispatch() {
  const { id,project_id } = useParams();
  const [workshop, setWorkshop] = useState(17);
  const navigate = useNavigate();
  const [refresh, setrefresh] = useState(false);
  const [projectList, setProjectList] = useState({});
  const [prodDetails, setProdDetails] = useState([]);
  const [openModal2, setOpenModal2] = useState(false);
const [getDispatch,setGetDispatch] = useState([]);
const [featuredispatch,setfeaturedispatch] = useState([]);
  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  };

  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  };
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  };
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`);
  };

  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };

  const handleDivClick17 = () => {
    navigate(`/dispatch/${id}`);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);
      const userRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/get_user_details_project_details?Email=${userEmail}`
      );

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
        Done_By: userRes.data.data[0].Name,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  const handleCreateNewClick = () => {
    navigate(`/dispatch-create-new-workshop/${id}`);
  };

  const handleUpdateClick = (project_id) =>{
    navigate(`/dispatch-update/${id}/${project_id}`);
  
  }
 

useEffect(()=>{
  async function dispatchdetails(){
    try{
      const res = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/getdispatchdetails`);
      console.log(res.data.results);
      setGetDispatch(res.data.results.reverse());

    }catch(error){
      console.error(error);
    }
  }
  dispatchdetails();
},[])

{console.log(getDispatch)}

useEffect(()=>{
  async function fetchRigDetails(){
  try{
    const response = await axios.get(
      "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/adminpermission_group"
    );
  
  

    const workshopdispatch= response.data.filter((el)=>{
      return(
      el.Group_Name === localStorage.getItem("Group") &&
      el.Header_Name === "Workshop Projects" && el.Feature_Name === "Workshop Dispatch" && (el.Access === "Can View" || el.Access === "Can Edit")
      );
    })

  
    setfeaturedispatch(workshopdispatch);

  }catch(error){
    console.error(error);
  }
  }
  fetchRigDetails();
    },[]);

  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}>
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
        <div
          className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick15}>
          Production
        </div>
        <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
        <div
          className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick17}>
          Dispatch
        </div>
        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}>
          Outsourcing
        </div>
        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}>
          Reports
        </div>
        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}>
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}>
          Items Received
        </div>
        <div
          className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}>
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}>
          WIP
        </div>
      </div>
      <div className={styles.maindiv}>
        <div className={styles.buttonContainer}>
          <button
            variant="contained"
            className={styles["btn-Createnew"]}
            onClick={
           handleCreateNewClick
            }>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z"
                fill="white"
              />
              <defs>
                <rect width="20" height="20" fill="white" />
              </defs>
            </svg>{" "}
            Create Dispatch
          </button>
        </div>
      
        
                   

      </div>
      
      <div className={styles.sections2}>
      {Array.isArray(getDispatch) &&
        getDispatch
          // .slice() // Create a shallow copy of the array
          // .sort((a, b) => b.dispatch_id - a.dispatch_id) // Sort in descending order based on the S_No
          .map((el, index) => (
            <div key={index} className={styles.projectList}
              onClick={() => handleUpdateClick(el.dispatch_id)}
            >
              <div className={styles.textContainer}>
                {`Dispatch ID: ${el.dispatch_id} \u00a0|\u00a0 
                  Purpose: ${el.Purpose || '-'} \u00a0|\u00a0 
                  Receiving Party: ${el.Receiving_Party || '-'} \u00a0|\u00a0 
                  Delivery Date: ${el.Delivery_date || '-'} \u00a0|\u00a0 
                  Created By: ${formdata1.Done_By || '-'} 
                `}
              </div>
              {/* <button
                className={styles.deliveryStatusButton}
                style={{ backgroundColor: getBackgroundColor(el), color: "white" }}>
                {getButtonText(el)}
              </button> */}
            </div>
          ))
 
      }
      </div>
    
  
   
    </div>
  );
}

export default Dispatch;
