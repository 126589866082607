import React from "react";
import styles from "./css/itemsreceived.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ItemsReceived() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);    //to display the loading screen when Save button is clicked, also to disable the Save button to prevent multiple clicks
  const [workshop] = useState(9);
  const [itemsReceived, setItemsReceived] = useState([]);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedLocations, setEditedLocations] = useState({});
  const [editedRemarks, setEditedRemarks] = useState({});
  const [changedItems, setChangedItems] = useState([]);

  const handleLocationChange = (id, value) => {
    setEditedLocations((prevLocations) => ({
      ...prevLocations,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  
  const handleRemarksChange = (id, value) => {
    setEditedRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  
  const handleEditClick = () => {
    setIsEditing(true);
    const initialEditedLocations = {};
    const initialEditedRemarks = {};
  
    itemsReceived.forEach((item) => {
      initialEditedLocations[item.S_No] = item.Location;
      initialEditedRemarks[item.S_No] = item.Remarks;
    });
  
    setEditedLocations(initialEditedLocations);
    setEditedRemarks(initialEditedRemarks);  
  };

  // const handleSaveClick = async () => {
  //   try {
  //     const updatedItems = itemsReceived
  //       .filter((item) => changedItems.includes(item.S_No))
  //       .map((item) => ({
  //         ...item,
  //         Location: editedLocations[item.S_No] !== undefined ? editedLocations[item.S_No] : null,
  //         Remarks: editedRemarks[item.S_No] !== undefined ? editedRemarks[item.S_No] : null,  
  //         // Location: editedLocations[item.S_No] || item.Location,
  //         // Remarks: editedRemarks[item.S_No] || item.Remarks,
  //       }));
  //     console.log("updatedItems:", updatedItems);
  //     await Promise.all(
  //       updatedItems.map((updatedItem) =>
  //         axios.put(
  //           `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_put?S_No=${updatedItem.S_No}`,
  //           updatedItem
  //         )
  //       )
  //     );

  //     // Fetch the updated data from the server after the API call
  //     const res = await axios.get(
  //       `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get?Project_Id=${id}`
  //     );

  //     // Update the state with the new data
  //     setItemsReceived(res.data.data);
  
  //     setIsEditing(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   // window.location.reload();
  // };

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const updatedItems = itemsReceived
        .filter((item) => changedItems.includes(item.S_No))
        .map((item) => {
          const updatedItem = {
            ...item,
            Location: editedLocations[item.S_No] !== undefined ? editedLocations[item.S_No] : null,
            Remarks: editedRemarks[item.S_No] !== undefined ? editedRemarks[item.S_No] : null,
          };
          // Omitting Entry_Bill_No from updatedItem
          delete updatedItem.Entry_Bill_No;
          return updatedItem;
        });
      console.log("updatedItems:", updatedItems);
      await Promise.all(
        updatedItems.map((updatedItem) =>
          axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_put?S_No=${updatedItem.S_No}`,
            updatedItem
          )
        )
      );

      // Fetch the updated data from the server after the API call
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get?Project_Id=${id}`
      );

      const itemsReceivedData = res.data.data;

      const updatedItemsReceived = await Promise.all(itemsReceivedData.map(async (item) => {
        const ergonBOERes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
        );
        console.log("ergonBOERes", ergonBOERes);
        return {
          ...item,
          Entry_Bill_No: ergonBOERes.data.data[0].Entry_Bill_No, // Assuming ergonBOEData contains the required information
        };
      }));

      console.log("Updated itemsReceived", updatedItemsReceived);

      // Update the state with the new data
      setItemsReceived(updatedItemsReceived);

      setIsEditing(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    // window.location.reload();
    setIsLoading(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
};

const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
};
const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
};

const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
};


const handleDivClick14 = () => {
  navigate(`/project-timeline-workshop/${id}`);
}
const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
};

const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
};

const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
};

const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
};
const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
};
const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
};
const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
};
const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
};
const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
};
const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });


  // useEffect(() => {
  //   const fetchProject = async () => {

  //     const res = await axios.get(
  //       "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
  //     );

  //     const res1 = await axios.get(
  //       `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get?Project_Id=${id}`
  //     );
  //     console.log("items received", res1.data.data);
  //     setItemsReceived(res1.data.data);
  //     const filter = res.data.data.filter((el) => el.ID_No == id);
  //     setFormData1({
  //       ...formdata1,
  //       salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
  //       PONO: filter[0]?.PO_No?.toString(),
  //       PO_Date: filter[0]?.PODate,
  //       QuoteNo: filter[0]?.Quote_No,
  //       QuoteDate: filter[0]?.Quote_Date,
  //       CostingID: filter[0]?.Costing_ID,
  //       ProjectName: filter[0]?.Project_Name,
  //       Scope: filter[0]?.Scope,
  //       projectDescription: filter[0]?.Project_Description,
  //       quantity: filter[0]?.Quantity,
  //       CustomerName: filter[0]?.Customer_Name,
  //       FirstArrival: filter[0]?.First_Arrival,
  //       TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
  //       opsEngineer: filter[0]?.Ops_Engineer,
  //       TargetManhours: filter[0]?.Target_Manhours,
  //       currentStatus: filter[0]?.Current_Status,
  //     });
  //     console.log(filter);
  //     console.log(id);
  //   };
  //   fetchProject();
  // }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const projectRes = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );

      const projectData = projectRes.data.data;

      const itemsRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get?Project_Id=${id}`
      );

      const itemsReceivedData = itemsRes.data.data;

      const updatedItemsReceived = await Promise.all(itemsReceivedData.map(async (item) => {
        const ergonBOERes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsreceived_get_ErgonBOE?Incoming_Delivery_ID=${item.Incoming_Delivery_ID}`
        );
        console.log("ergonBOERes", ergonBOERes);
        return {
          ...item,
          Entry_Bill_No: ergonBOERes.data.data[0].Entry_Bill_No, // Assuming ergonBOEData contains the required information
        };
      }));

      console.log("Updated itemsReceived", updatedItemsReceived);

      // Fetch consumption details
      const consumptionRes = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/items_received_csp_consumption_details?Project_ID=${id}`
      );

      const consumptionDetails = consumptionRes.data.data;

      // Add Qty_Used to each item in updatedItemsReceived
      const updatedItemsWithConsumption = updatedItemsReceived.map((item) => {
        const totalQtyConsumed = consumptionDetails
          .filter(
            (consumption) =>
              consumption.Item_Name === item.Item_Name &&
              consumption.Part_Number === item.Part_Number &&
              parseFloat(consumption.Incoming_Delivery_ID) === parseFloat(item.Incoming_Delivery_ID)
          )
          .reduce((total, consumption) => total + parseFloat(consumption.Qty_Consumed) || 0, 0);

        return {
          ...item,
          Qty_Used: totalQtyConsumed,
        };
      });

      setItemsReceived(updatedItemsWithConsumption);
      console.log("updatedItemsWithConsumption", updatedItemsWithConsumption);

      // setItemsReceived(updatedItemsReceived);

      const filter = projectData.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });

      console.log(filter);
      console.log(id);
    };
    fetchProject();
  }, []);

  return (
    <div>
      <LoadingScreen isLoading={isLoading} />
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
          <div
              className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick}>
              Project Checklist
          </div>
          <div
              className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick1}>
              Project Details
          </div>
          <div
              className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick10}
          >
              Team Details
          </div>
          <div
              className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick12}>
              Inventory Used
          </div>
          <div
              className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick13}>
              Purchases
          </div>
          <div
              className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick2}>
              Timesheet
          </div>
          <div
              className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick3}>
              Deliveries
          </div>
          {/* <div
              className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick4}>
              Outgoing Deliveries
          </div> */}
          <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
          <div
              className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick5}>
              Outsourcing
          </div>
          <div
              className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick6}>
              Reports
          </div>
          <div
              className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick7}>
              Inspections
          </div>
          <div
              className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick8}>
              Items Received
          </div>
          <div
              className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
              onClick={handleDivClick9}>
              Finished goods
          </div>
      </div>

      {!isEditing ? (
        // Non-editable display of data
        <div className={styles.maindiv}>
          <div className={styles.buttonsdiv}>
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Traceability Number</th>
                  <th>UOM</th>
                  <th>Balance Qty</th>
                  <th>Qty Received</th>
                  <th>Qty Used</th>
                  <th>Arrival Date</th>
                  <th>Entry Bill No.</th>
                  <th>Incoming Inspection ID</th>
                  <th>Incoming Delivery ID</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.length > 0 ? (
                  itemsReceived.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.Part_Number}</td>
                      <td>{item.Traceability_Number}</td>
                      <td>{item.UOM}</td>
                      <td>{(parseFloat(item.Quantity_Received) - parseFloat(item.Qty_Used)) ? (parseFloat(item.Quantity_Received) - parseFloat(item.Qty_Used)) : "-"}</td>
                      <td>{item.Quantity_Received ? item.Quantity_Received : "-"}</td>
                      <td>{item.Qty_Used ? item.Qty_Used : "-"}</td>
                      <td>{new Date(item.Arrival_Date).toLocaleDateString('en-GB')}</td>
                      <td>{item.Entry_Bill_No}</td>
                      <td>{item.Incoming_Inspection_ID}</td>
                      <td>{item.Incoming_Delivery_ID}</td>
                      <td>{item.Location}</td>
                      <td>{item.Remarks}</td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan="12">No items received yet.</td>
                    </tr>
                  )
                }  
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Editable data state
        <div className={styles.maindiv}>

          <div className={styles.buttonsdiv} >
            <button onClick={handleSaveClick} style={{ marginRight: "10px" }} disabled={isLoading}>
              Save{" "}
            </button>
          
            <button onClick={handleCancelClick} style={{ background: "white", ":hover": { background: "#F5F5F5", }, border: "1px solid #5B6BE1", color: "#5B6BE1", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
              Cancel{" "}
            </button>
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Traceability Number</th>
                  <th>UOM</th>
                  <th>Balance Qty</th>
                  <th>Qty Received</th>
                  <th>Qty Used</th>
                  <th>Arrival Date</th>
                  <th>Entry Bill No.</th>
                  <th>Incoming Inspection ID</th>
                  <th>Incoming Delivery ID</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.length > 0 ? (
                  itemsReceived.map((item, index) => (
                    <tr key={item.S_No}>
                      <td>{index + 1}</td>
                      <td>{item.Item_Name}</td>
                      <td>{item.Part_Number}</td>
                      <td>{item.Traceability_Number}</td>
                      <td>{item.UOM}</td>
                      <td>{(parseFloat(item.Quantity_Received) - parseFloat(item.Qty_Used)) ? (parseFloat(item.Quantity_Received) - parseFloat(item.Qty_Used)) : "-"}</td>
                      <td>{item.Quantity_Received ? item.Quantity_Received : "-"}</td>
                      <td>{item.Qty_Used ? item.Qty_Used : "-"}</td>
                      <td>{new Date(item.Arrival_Date).toLocaleDateString('en-GB')}</td>
                      <td>{item.Entry_Bill_No}</td>
                      <td>{item.Incoming_Inspection_ID}</td>
                      <td>{item.Incoming_Delivery_ID}</td>
                      <td>
                        {/* <select
                          className={styles.textbox1}
                          value={editedLocations[item.S_No]}
                          onChange={(e) =>
                            handleLocationChange(item.S_No, e.target.value)
                          }
                        >
                          <option value="">Select Location</option>
                          <option value="Shelf 1">Shelf 1</option>
                          <option value="Shelf 2">Shelf 2</option>
                          <option value="Shelf 3">Shelf 3</option>
                        </select>
                        <svg
                          style={{ marginLeft: "-14%", marginTop: "-1%", pointerEvents: "none" }}
                          width="24"
                          height="24"
                          viewBox="0 0 28 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                            fill="black"
                          />
                        </svg> */}
                        <input
                        className={styles.textbox1}
                        style={{ paddingRight: "8px" }}
                        type ="text"
                        value={editedLocations[item.S_No]}
                        onChange={(e) =>
                          handleLocationChange(item.S_No, e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className={styles.textbox1}
                          style={{ paddingRight: "8px" }}
                          type="text"
                          value={editedRemarks[item.S_No]}
                          onChange={(e) =>
                            handleRemarksChange(item.S_No, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan="12">No items received yet.</td>
                    </tr>
                  )
                }  
              </tbody>
            </table>
          </div>
        </div>
      )}

    </div>
  );
}
export default ItemsReceived;