import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_trading_update.module.css"; // Use the same CSS module
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import TabsBarSparesTrading from './TabsBarSparesTrading';

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiPickersBasePicker-pickerView": {
    backgroundColor: "#DADCE0",
  },
  "& .MuiOutlinedInput-root": {
    width: "230px",
    "&:hover": {
      "& fieldset": {
        borderColor: "1px solid #DADCE0",
      },
    },
    "&:not(.Mui-focused)": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
      },
    },
    "&.Mui-focused": {
      "& fieldset": {
        border: "1px solid #DADCE0",
        borderRadius: "8px",
      },
    },
  },
  "& input.Mui-disabled": {
    opacity: 1,
    "-webkit-text-fill-color": "black", // For WebKit-based browsers
    color: "black", // Fallback for other browsers
  },
}));

const SparesTradingDetailsUpdate = () => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    Sales_Order_No: "",
    PO_No: "",
    PO_Date: null,
    Quote_No: "",
    Quote_Date: null,
    Costing_ID: "",
    Customer_Name: "",
    Scope: "",
    Scope_Description: "",
    Estimated_Date_Of_Dispatch: null,
    Ops_Engineer: "",
    Current_Status: "",
  });
  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_get?ID_No=${id}`
        );

        if (response.data.success) {
          const data = response.data.data[0];
          setFormData({
            Sales_Order_No: data.Sales_Order_No,
            PO_No: data.PO_No,
            PO_Date: data.PO_Date,
            Quote_No: data.Quote_No,
            Quote_Date: data.Quote_Date,
            Costing_ID: data.Costing_ID,
            Customer_Name: data.Customer_Name,
            Scope: data.Scope,
            Scope_Description: data.Scope_Description,
            Estimated_Date_Of_Dispatch: data.Estimated_Date_Of_Dispatch,
            Ops_Engineer: data.Ops_Engineer,
            Current_Status: data.Current_Status,
          });
          setOriginalData(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error here
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value ? value.format("YYYY-MM-DD") : null,
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  // const handleSave = async (e) => {
  //   e.preventDefault();

  //   const payload = { ...formData };

  //   try {
  //     const response = await axios.put(
  //       `/api/data/sparestrading_details_update_put?ID_No=${id}`,
  //       payload
  //     );

  //     if (response.data.success) {
  //       setOriginalData(formData);
  //       setEditMode(false);
  //     }
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Extract relevant fields from formData
    const {
      Sales_Order_No,
      PO_No,
      PO_Date,
      Quote_No,
      Quote_Date,
      Costing_ID,
      Customer_Name,
      Scope,
      Scope_Description,
      Estimated_Date_Of_Dispatch,
      Ops_Engineer,
      Current_Status,
    } = formData;

    // Construct the payload for the API request
    const payload = {
      Sales_Order_No,
      PO_No,
      PO_Date,
      Quote_No,
      Quote_Date,
      Costing_ID,
      Customer_Name,
      Scope,
      Scope_Description,
      Estimated_Date_Of_Dispatch,
      Ops_Engineer,
      Current_Status,
    };

    try {
      // Make the API call to update the data
      const response = await axios.put(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_details_update_put?ID_No=${id}`, payload);

      if (response.data.success) {
        // If the update is successful, update the originalData and reset the form to non-editable mode
        setOriginalData(formData);
        setEditMode(false);
      }

      // Optionally refresh the page or component data
      // window.location.reload(); // Uncomment if you want to refresh the entire page

    } catch (error) {
      console.error('Error saving data:', error);
      // Handle error here
    }
  };

  const handleCancel = () => {
    setFormData(originalData);
    setEditMode(false);
  };

  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          SO: {formData.Sales_Order_No}
        </label>
        <label className={styles.aryl}>
          PO: {formData.PO_No}
        </label>
        <label className={styles.aryl}>
          Customer Name: {formData.Customer_Name}
        </label>
      </div>

      <TabsBarSparesTrading activeTab="Project Details" />

      <div className={styles.sections}>
        <div className={styles.buttonsdiv}>
          {editMode ? (
            <div style={{ display: "flex" }}>
              <button
                variant="contained"
                onClick={handleSave}
              >
                Save Changes
              </button>
              <button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancel}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              variant="contained"
              onClick={() => handleEdit()}
            >
              Edit
            </button>
          )}
        </div>

        <form style={{ display: "flex", justifyContent: "space-around" }}>
          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Sales Order No</label>
              <input
                type="text"
                name="Sales_Order_No"
                value={formData.Sales_Order_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div className={styles.formElement}>
              <label>PO Number</label>
              <input
                type="text"
                name="PO_No"
                value={formData.PO_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>PO Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="PO_Date"
                  value={
                    formData.PO_Date ? dayjs(formData.PO_Date, "YYYY-MM-DD") : null
                  }
                  onChange={(value) => handleDateChange("PO_Date", value)}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={!editMode}
                  className={editMode ? null : styles.textbox2}
                />
              </LocalizationProvider>
            </div>

            <div className={styles.formElement}>
              <label>Quote No</label>
              <input
                type="text"
                name="Quote_No"
                value={formData.Quote_No}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>Quote Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="Quote_Date"
                  value={
                    formData.Quote_Date
                      ? dayjs(formData.Quote_Date, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDateChange("Quote_Date", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={!editMode}
                  className={editMode ? null : styles.textbox2}
                />
              </LocalizationProvider>
            </div>

            <div className={styles.formElement}>
              <label>Costing ID</label>
              <input
                type="text"
                name="Costing_ID"
                value={formData.Costing_ID}
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div className={styles.formElement}>
              <label>Customer Name</label>
              <select
                value={formData.Customer_Name}
                name="Customer_Name"
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              >
                <option value="">Select value</option>
                <option value="Shelf Drilling">Shelf Drilling</option>
                <option value="Valaris">Valaris</option>
                <option value="ARO Drilling">ARO Drilling</option>
                <option value="ADES">ADES</option>
                <option value="COC">COC</option>
                <option value="Northern Offshore">Northern Offshore</option>
                <option value="Melius">Melius</option>
                <option value="Goltens Co Ltd. Dubai Branch">Goltens Co Ltd. Dubai Branch</option>
              </select>
              <svg
                style={{ position: "absolute", right: "15" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                  fill="black"
                />
              </svg>
            </div>


          </div>

          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Scope</label>
              <input
                type="text"
                name="Scope"
                value={formData.Scope}
                className={styles.textbox1}
                disabled={true}
                readOnly={true}
              />
            </div>

            <div className={styles.formElement}>
              <label>Scope Description</label>
              <textarea
                name="Scope_Description"
                value={formData.Scope_Description}
                onChange={handleInputChange}
                className={editMode ? styles.textbox1b : styles.textbox1a}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>

            <div
              className={`${styles.formElement} ${styles.datepickerInput}`}
            >
              <label>Estimated Date Of Dispatch</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  name="Estimated_Date_Of_Dispatch"
                  value={
                    formData.Estimated_Date_Of_Dispatch
                      ? dayjs(formData.Estimated_Date_Of_Dispatch, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(value) =>
                    handleDateChange("Estimated_Date_Of_Dispatch", value)
                  }
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  disabled={!editMode}
                  className={editMode ? null : styles.textbox2}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className={styles.splitHalfSection}>
            <div className={styles.formElement}>
              <label>Ops Engineer</label>
              <select
                value={formData.Ops_Engineer}
                name="Ops_Engineer"
                onChange={handleInputChange}
                className={editMode ? null : styles.textbox1}
                disabled={!editMode}
                readOnly={!editMode}
              >
                <option value=""> Select the Member</option>
                <option value="Jancy">Jancy</option>
                <option value="Charulatha">Charulatha</option>
              </select>
              <svg
                style={{ position: "absolute", right: "15" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                  fill="black"
                />
              </svg>
            </div>

            <div className={styles.formElement}>
              <label>Current Status</label>
              <textarea
                name="Current_Status"
                value={formData.Current_Status}
                onChange={handleInputChange}
                className={editMode ? styles.textbox1b : styles.textbox1a}
                disabled={!editMode}
                readOnly={!editMode}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SparesTradingDetailsUpdate;
