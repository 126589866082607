import React, { useState, useEffect } from "react";

import "./App.css";

import Navbar from "./Components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Dashboardalerts from "./Components/Dashboardalerts";
import DailyTracker from "./Components/DailyTracker";
import SurveySchedule from "./Components/SurveySchedule";
import ReferenceDetails from "./Components/ReferenceDetails";
import LegalDetails from "./Components/Legaldetails";
import ProfessionalDetails from "./Components/ProfessionalDetails";
import Templates from "./Components/Templates";
import CreatingANewProject from "./Components/Projects";
import Documents from "./Components/Documents";
import Mobilizations from "./Components/Mobilizations";
import Signout from "./Components/Signout";
import RigDetails from "./Components/Rigdetails";
import withAuth from "./Components/withAuth";
import LoginPage from "./Components/loginpage";
import ToolsRegister from "./Components/Toolsregister";
import ProjectListingPage from "./Components/Projectlistingpage";
import CreateProject from "./Components/Createproject";
import ProgressSteps from "./Components/Projectreport";
import Dosplan from "./Components/Dosplan";
import Workplan from "./Components/Workplan";
import HandBook from "./Components/Handbook";
import Equipmentform1 from "./Components/Equipmentform1";
import Equipmentform2 from "./Components/Equipmentform2";
import Preservationform from "./Components/Preservationform";
import Cocform from "./Components/Cocform";
import ShowHandbook from "./Components/ShowHandbook";
import Consumables from "./Components/Consumables";
import Inventory from "./Components/Inventory";
import Bills from "./Components/Bills";
import Workshopprojects from "./Components/Workshopprojects";
import WorkshopCreateNew from "./Components/WorkshopCreateNew";
import ProjectDetailsWorkshop from "./Components/ProjectDetailsWorkshop";
import ProjectChecklistWorkshop from "./Components/ProjectChecklistWorkshop";
import TimesheetWorkshop from "./Components/TimesheetWorkshop";
import IncomingDeliveries from "./Components/IncomingDeliveries";
import OutgoingDeliveries from "./Components/OutgoingDeliveries";
import IncomingDeliveries_New from "./Components/IncomingDeliveries_New";
import IncomingDeliveries_Update from "./Components/IncomingDeliveries_Update";
import Outsourcing from "./Components/Outsourcing";
import ReportsWorkshop from "./Components/ReportsWorkshop";
import Inspections from "./Components/Inspections";
import ItemsReceived from "./Components/ItemsReceived";
import FinishedGoods from "./Components/FinishedGoods";
import Toolbox_edit from "./Components/Toolbox_edit";
import Toolbox_edit_table from "./Components/toolbox_edit_table";
import Toolbox from "./Components/Toolbox";
// import Admintaskbar from "./Components/admintaskbar";
import Admintaskbar from "./Components/admintaskbar";
import Workshoplist from "./Components/workshopprojectlisting"
import DailyTrackerWorkshop from "./Components/DailyTrackerWorkshop";
import TeamDetailsWorkshop from "./Components/TeamDetailsWorkshop";
import ConsumablesWorkshop from "./Components/ConsumablesWorkshop";
import PurchasesWorkshop from "./Components/PurchasesWorkshop";
import AdminPermission from "./Components/Admin_Permissions";
import Newtimesheet from "./Components/newtimesheet"
import Timesheetlist from "./Components/timesheetlist"
import Newtimesheetedit from "./Components/Timesheet_workshop_edit"
import Sparesmobilization from "./Components/Sparesmobilization";
import Apidocs from "./Components/apidocs";
import QMStracker from "./Components/QMStracker";
import Incoming_Inspections from "./Components/Incoming_Inspections";
import Itemmaster from "./Components/Itemmaster";
import CustomerPropertyLogs from "./Components/CustomerPropertyLogs";
import CustomerLogsDetails from "./Components/CustomerLogsDetails";
import Availability from "./Components/Availability";
import Attendance from "./Components/Attendance";
import Utilization from "./Components/Utilization";
import Mandays from "./Components/Mandays";
import Purchases from "./Components/Purchases";
import PurchaseCreateNew from "./Components/PurchaseCreateNew";
import PurchaseUpdate from "./Components/PurchaseUpdate";
import ItemDetails from "./Components/ItemDetails";
import Purchase_Incoming_Inspections from "./Components/Purchase_Incoming_Inspections";
import PurchaseIncomingInspectionsUpdate from "./Components/PurchaseIncomingInspectionsUpdate";
import ErgonPropertylogs from "./Components/ErgonPropertylogs";
import ErgonInventoryLogsDetails from "./Components/ErgonInventoryLogsDetails";
import Purchase_Incoming_Deliveries from "./Components/Purchase_Incoming_Deliveries";
import PurchaseIncomingCreateNew from "./Components/PurchaseIncomingCreateNew";
import PurchaseIncomingUpdate from "./Components/PurchaseIncomingUpdate";
import UniversalIncomingDeliveries from "./Components/UniversalIncomingDeliveries";
import Residentvisas from "./Components/Residentvisas";
import Mobilizationdocumets from "./Components/Mobilizationdocuments";
import Travelattendance from "./Components/Travelattendance";
import Travelattendanceview from "./Components/Travelattendanceview";
import Inventory_Utilization from "./Components/Inventory_Utilization";
import InventoryUtilizationLogs from "./Components/InventoryUtilizationLogs";
import Monthmandays from "./Components/Monthmandays";
import Yearmandays from "./Components/Yearmandays";
import ProtectedRoute from './Components/withAuth';
import ProjectTimelineWorkshop from "./Components/ProjectTimelineWorkshop";
import Attendancefile from "./Components/attendancefile";
import PurchaseItemsReceived from "./Components/PurchaseItemsReceived";
import Universal_inspections from "./Components/Universal_inspections";
import MyProvider from "./services/provider";
import NotificationBell from "./Components/NotificationBell";
import ProductionListing from "./Components/ProductionListing";
import Production_Create_New from "./Components/Production_Create_New";
import WipTab from "./Components/WipTab";
import Chatbot from "./Components/chatbot";
import Visual_Inspections_Create_New from "./Components/Visual_Inspections_Create_New";
import VisualInspectionsUpdate from "./Components/VisualInspectionsUpdate";
import Production_Update from "./Components/Production_Update";
import Dispatch from "./Components/Dispatch";
import DispatchCreateNew from "./Components/DispatchCreateNew";
import DispatchUpdate from "./Components/DispatchUpdate";
import EmailForm from "./Components/Emailform";
import Responsibilitymatrix from "./Components/Responsibilitymatrix";
import SparestradingWorkshop from "./Components/SparestradingWorkshop";
import SparestradingWorkshopCreatenew from "./Components/SparestradingWorkshopCreatenew";
import SparesTradingDetailsUpdate from "./Components/SparesTradingDetailsUpdate";
import SparesTradingWorkshopItemDetails from "./Components/SparesTradingWorkshopItemDetails";
import SparesTradingWorkshopInventoryUsed from "./Components/SparesTradingWorkshopInventoryUsed";
import IncomingInspectionReport from "./Components/IncomingInspection";
import SparestradingWorkshopDispatch from "./Components/SparestradingWorkshopDispatch";
import SparestradingDispatchCreatenew from "./Components/SparestradingDispatchCreatenew"
import SparestradingDispatchUpdate from "./Components/SparestradingDispatchUpdate";
import SparesTradingWorkshopOutgoingInspectionsListing from "./Components/SparesTradingWorkshopOutgoingInspectionsListing";
import SparesTradingWorkshopOutgoingInspectionsUpdate from "./Components/SparesTradingWorkshopOutgoingInspectionsUpdate";
import SparestradingWorkshopDeliveries from "./Components/SparesTradingWorkshopDeliveries";
import SparestradingWorkshopDeliveriesUpdate from "./Components/SparestradingWorkshopDeliveriesUpdate";
import SparesTradingChecklist from "./Components/SparesTradingChecklist";
import WorkshopSparesInquiryCreateNew from "./Components/WorkshopSparesInquiryCreateNew";
import WorkshopSparesInquiryListing from "./Components/WorkshopSparesInquiryListing";
import SparesTradingInquiryDetails from "./Components/SparesTradingInquiryDetails";
import SparesTradingInquiryItemDetails from "./Components/SparesTradingInquiryItemDetails";
import SparesTradingInquiryVendorQuotes from "./Components/SparesTradingInquiryVendorQuotes";
import SparesTradingInquiryCustomerQuote from "./Components/SparesTradingInquiryCustomerQuote";
import SupplierDetailsListing from "./Components/SupplierDetailsListing";
import SupplierDetailsCreateNew from "./Components/SupplierDetailsCreateNew";
import SparesInquiryChecklist from "./Components/SparesInquiryChecklist";
import SupplierDetailsViewDetails from "./Components/SupplierDetailsViewDetails";
import SupplierDetailsViewCatalogue from "./Components/SupplierDetailsViewCatalogue";
import SupplierItemMaster from "./Components/SupplierItemMaster";
import SparesTradingInquiryRFQ from "./Components/SparesTradingInquiryRFQ";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = localStorage.getItem('token');
  const ProjectReport = () => {};
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn"));
  }, [isLoggedIn]);

  useEffect(() => {

    // Subscribe to auth changes
    const checkAuth = () => {
      setIsLoggedIn(!!token);
    };

    // Listen to storage changes or implement a better state management solution
    window.addEventListener('storage', checkAuth);

    // Initial check
    checkAuth();

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('storage', checkAuth);
    };
  }, []);

  return (
    <MyProvider>
    <div>
      <div className="bg-gray-900 flex flex-col gap-10 h-screen items-center justify-center">
        <ProjectReport />
      </div>

      <BrowserRouter>
      {isLoggedIn && token && <Navbar />}
      {isLoggedIn && token && <NotificationBell />}
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
          <Route path="/admintaskbar" element={<Admintaskbar />} />
          <Route path="/Workshoplist" element={<Workshoplist/>} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/monthmandays" element={<Monthmandays/>}/>
          <Route path="/yearmandays" element={<Yearmandays/>}/>
          <Route path="/dailytracker" element={<DailyTracker />} />
          <Route path="/attendance" element={<Attendance/>}/>
          <Route path="/utilization" element={<Utilization />} />
          <Route path="/mandays" element={<Mandays/>}/>
          <Route path="/surveyschedule" element={<SurveySchedule />} />
          <Route path="/referencedetails" element={<ReferenceDetails />} />
          <Route path="/rigdetails" element={<RigDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard-alerts" element={<Dashboardalerts />} />
          <Route path="/handbook" element={<ShowHandbook />} />
          <Route path="/legaldetails" element={<LegalDetails />} />
          <Route path="/toolsregister" element={<ToolsRegister />} />
          <Route path="/qmstracker" element={<QMStracker />} />
          <Route
            path="/professionaldetails"
            element={<ProfessionalDetails />}
          />
          <Route path="/templates" element={<Templates />} />

          <Route path="/projects" element={<ProjectListingPage />} />
          <Route path="/createproject" element={<CreateProject />} />

          <Route path="/project_checklist/:id" element={<ProgressSteps />} />
          <Route path="spares/:id" element={<Sparesmobilization />} />
          <Route path="/Consumables" element={<Consumables />} />
          <Route path="/dosplan/:id" element={<Dosplan />} />
          <Route path="/workplan/:id" element={<Workplan />} />
          <Route path="/equipmentform1" element={<Equipmentform1 />} />
          <Route path="/equipmentform2" element={<Equipmentform2 />} />
          <Route path="/preservationform" element={<Preservationform />} />
          <Route path="/cocform" element={<Cocform />} />
          <Route path="/createnew" element={<CreatingANewProject />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/workshopprojects" element={<Workshopprojects />} />
          <Route path="/Chatbot" element={<Chatbot />} />
          <Route path="/project-checklist-workshop/:id" element={<ProjectChecklistWorkshop />} />
          <Route path="/workshopcreatenew" element={<WorkshopCreateNew />} />
          <Route
            path="/project-details-workshop/:id"
            element={<ProjectDetailsWorkshop />}
          />
          <Route
            path="/project-checklist-workshop/:id"
            element={<ProjectChecklistWorkshop />}
          />
           <Route
            path="/timesheet-workshop/:id"
            element={<TimesheetWorkshop />}          
          />
            <Route path="/incoming-inspections-workshop/:id" element={<Incoming_Inspections />} />
          <Route path="items-master" element={<Itemmaster />} />
          <Route path="/incoming-deliveries-workshop/:id" element={<IncomingDeliveries />} />
          <Route path="/incomingdeliveriesnew/:id" element={<IncomingDeliveries_New />} />
          <Route path="/incomingdeliveriesupdate/:id/:S_No" element={<IncomingDeliveries_Update />} />
          <Route path="/outgoing-deliveries-workshop/:id" element={<OutgoingDeliveries />} />
          <Route path="/outsourcing-workshop/:id" element={<Outsourcing />} />
          <Route path="/reports-workshop/:id" element={<ReportsWorkshop />} />
          <Route path="/inspections-workshop/:id" element={<Inspections />} />
          <Route path="items-received-workshop/:id" element={<ItemsReceived />} />
          <Route path="/finished-goods-workshop/:id" element={<FinishedGoods />} />
          <Route path="/mobilizations" element={<Mobilizations />} />
          <Route path="/toolbox_createnew" element={<Toolbox />}/>
          <Route path="/toolbox_edit/:selectedDate" element={<Toolbox_edit />}/>
          <Route path="/toolbox_edit_table/:selectedDate" element={<Toolbox_edit_table />}/>
          <Route path="/Newtimesheet" element={<Newtimesheet/>} />
          <Route path="/Timesheet_edit/:id" element={<Newtimesheetedit/>} />
          <Route path="/dailytrackerworkshop" element={<DailyTrackerWorkshop />} />
          <Route path="/team-details-workshop/:id" element={<TeamDetailsWorkshop/>}/>
          <Route path="/inventory-used-workshop/:id" element={<ConsumablesWorkshop />} />
          <Route path="/purchases-workshop/:id" element={<PurchasesWorkshop />} />
          <Route path="/Timesheetlist" element={<Timesheetlist />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/apidocs/:id" element={< Apidocs />} />
          <Route path="/travelattendance_createnew" element={<Travelattendance />}/>
          <Route path="/travelattendance_view" element={<Travelattendanceview />}/>
          <Route path="/adminpermission" element={<AdminPermission />} />
          <Route path="/customerpropertylogs" element={<CustomerPropertyLogs />} />
          <Route path="/customerlogsdetails/:Item_Name/:Item_Id/:part_number" element={<CustomerLogsDetails/>}/>
          <Route path="/availability" element={<Availability />} />
          <Route path="/Purchases" element={< Purchases />} />
          <Route path="/purchasecreatenew" element={<PurchaseCreateNew />} />
          <Route path="/purchase_details/:Purchase_id" element={<PurchaseUpdate />} />
          <Route path="/item_details/:Purchase_id" element={<ItemDetails />} />
          <Route path="/purchase_incoming_inspections/:Purchase_id" element={<Purchase_Incoming_Inspections />} />
          <Route path="/purchase_incoming_inspections_update/:Purchase_id/:IncomingDeliveryID" element={<PurchaseIncomingInspectionsUpdate />} />
          <Route path="/ergoninventorylogs" element={<ErgonPropertylogs />} />
          <Route path="/ergoninventorylogsdetails/:Item_Id/:part_number/:Item_Name/:Manufacturer_Part_Number" element={<ErgonInventoryLogsDetails />} />
          <Route path="/purchase_incoming_deliveries/:Purchase_id" element={<Purchase_Incoming_Deliveries />} />
          <Route path="/purchase_create_new_incoming/:Purchase_id" element={<PurchaseIncomingCreateNew />} />
          <Route path="/purchaseincomingdeliveriesupdate/:Purchase_id/:S_No" element={<PurchaseIncomingUpdate />} />
          <Route path="/universal_deliveries" element={<UniversalIncomingDeliveries />} />
          <Route path="/universal_inspections" element={<Universal_inspections />} />
          <Route path="/residentvisas" element={<Residentvisas/>}/>
          <Route path="/mobilizationdocuments" element={<Mobilizationdocumets/>}/>
          <Route path="/inventory_utilization" element={<Inventory_Utilization/>}/>
          <Route path="/InventoryUtilizationLogs" element={<InventoryUtilizationLogs />} />
          <Route path="/project-timeline-workshop/:id" element={<ProjectTimelineWorkshop />} />
          <Route path="/attendancefile" element={<Attendancefile/>}/>
          <Route path="/purchase_items_received/:Purchase_id" element={<PurchaseItemsReceived />} />
          <Route path="/project-timeline-workshop/:id" element={<ProjectTimelineWorkshop />} />
          <Route path="/production-workshop/:id" element={<ProductionListing />} />
          <Route path="/production-create-new-workshop/:project_id" element={<Production_Create_New />} />
          <Route path="/Wip-workshop/:id" element={<WipTab/>}/>
          <Route path="/visual_inspections_create_new/:Project_ID" element={<Visual_Inspections_Create_New/>}/>
          <Route path="/visual_inspections_update/:project_id/:visual_inspection_id" element={<VisualInspectionsUpdate/>}/>
          <Route path="/production_update/:project_id/:production_id" element={<Production_Update />} />
          {/* <Route path="/chat" element={<ChatComponent />} /> */}
          <Route path="/dispatch/:id" element={<Dispatch/>}/>
              <Route path="/dispatch-create-new-workshop/:id" element={<DispatchCreateNew/>}/>
              <Route path="/dispatch-update/:id/:project_id" element={<DispatchUpdate/>}/>
              <Route path="/email" element={<EmailForm/>}/>
              <Route path="/responsibilitymatrix" element={<Responsibilitymatrix/>}/>
              <Route path="/workshop-spares-trading" element={<SparestradingWorkshop/>} />
              <Route path="/workshop-spares-trading-createnew" element={<SparestradingWorkshopCreatenew/>}/>
              <Route path="/workshop-spares-trading-details-update/:id" element={<SparesTradingDetailsUpdate/>}/>
              <Route path="/workshop-spares-trading-item-details/:id" element={<SparesTradingWorkshopItemDetails/>}/>
              <Route path="/workshop-spares-trading-inventory-used/:id" element={<SparesTradingWorkshopInventoryUsed/>}/>
              <Route path="/incominginspectionreport/:id/:deliveryId" element={<IncomingInspectionReport/>}/>
              <Route path="/workshop-spares-trading-dispatch/:id" element={<SparestradingWorkshopDispatch/>}/> 
              <Route path="/workshop-spares-trading-dispatchcreatenew/:id" element={<SparestradingDispatchCreatenew/>}/>
              <Route path="/workshop-spares-trading-dispatchupdate/:id/:project_id" element={<SparestradingDispatchUpdate/>}/>
              <Route path="/workshop-spares-trading-outgoing-inspections-listing/:id" element={<SparesTradingWorkshopOutgoingInspectionsListing/>}/>
              <Route path="/workshop-spares-trading-outgoing-inspections-update/:id/:outgoing_inspection_id" element={<SparesTradingWorkshopOutgoingInspectionsUpdate/>}/>
              <Route path="/workshop-spares-trading-deliveries/:id" element={<SparestradingWorkshopDeliveries/>}/>
              <Route path="/workshop-spares-trading-deliveries-update/:id/:Sales_Order_No/:Delivery_ID" element={<SparestradingWorkshopDeliveriesUpdate/>}/>
              <Route path="/workshop-spares-trading-project-checklist/:id" element={<SparesTradingChecklist />} />
              <Route path="/workshopspares-inquiry-createnew" element={<WorkshopSparesInquiryCreateNew/>} />
              <Route path="/workshopspares-inquiry-listing" element={<WorkshopSparesInquiryListing/>}/>
              <Route path="/workshopspares-inquiry-details/:id" element={<SparesTradingInquiryDetails />} />
              <Route path="/workshop-spares-inquiry-item-details/:id" element={<SparesTradingInquiryItemDetails />} />
              <Route path="/workshop-spares-inquiry-vendor-quotes/:id" element={<SparesTradingInquiryVendorQuotes />} />
              <Route path="/workshop-spares-inquiry-customer-quote/:id" element={<SparesTradingInquiryCustomerQuote />} />
              <Route path="/supplierdetails" element={<SupplierDetailsListing/>}/>
              <Route path="/supplierdetails-createnew" element={<SupplierDetailsCreateNew/>}/>
              <Route path="/workshop-spares-inquiry-checklist/:id" element={<SparesInquiryChecklist />} />
              <Route path="/supplierdetails-viewdetails/:Supplier_ID/:Supplier_Name" element={<SupplierDetailsViewDetails />} />
              <Route path="/supplierdetails-viewcatalogue/:id/:name" element={<SupplierDetailsViewCatalogue />} />
              <Route path="/supplierItemMaster" element={<SupplierItemMaster />} />
              <Route path="/workshop-spares-inquiry-rfq/:id" element={<SparesTradingInquiryRFQ />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
    </MyProvider>
  );
}

export default App;
