import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "./css/workshop_spares_inquiry_rfq.module.css";
import TabsBarSparesInquiry from './TabsBarSparesInquiry';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../src/Components/Erogonlogo.png";

const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const formatDate = (dateString) => {
  if (!dateString) return "-";
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

export const generatePDFBlob = (formData, ergonRef, supplierDetails, rfqNo, items) => {
  // Create a new jsPDF instance in landscape mode
  const pdf = new jsPDF("l", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Add border around the logo and heading
  pdf.setLineWidth(0.25);
  pdf.rect(14, 10, pageWidth - 30, 30);  // Rect around the logo and heading

  // Add the logo and title
  const logoImg = new Image();
  logoImg.src = logo;  // Assuming 'logo' is a base64 or URL string
  pdf.addImage(logoImg, "PNG", 30, 15, 55, 20);
  
  pdf.setFontSize(21);
  pdf.text("REQUEST FOR QUOTATION", pageWidth / 2, 33, null, null, "left");



  // Metadata Table (same width as the form data table)
  pdf.autoTable({
    startY: 45,
    tableWidth: pageWidth - 20,
    head: [["Supplier Name", `${supplierDetails?.Supplier_Name ? supplierDetails?.Supplier_Name : "-"}`, "RFQ No", `${rfqNo ? rfqNo : "-"}` ]],
    body: [
      ["Tax Registration No", `${supplierDetails?.Tax_Reg_No ? supplierDetails?.Tax_Reg_No : "-"}`, "RFQ Date", `${formData?.RFQDate ? formatDate(formData?.RFQDate) : "-"}`],
      ["Address", `${supplierDetails?.Address ? supplierDetails?.Address : "-"}`, "Expected Delivery Date", `${formData?.ExpDeliveryDate ? formData?.ExpDeliveryDate : "-"}`],
      ["Email", `${supplierDetails?.Email ? supplierDetails?.Email : "-"}`, "Closeout Date", `${formData?.CloseoutDate ? formatDate(formData?.CloseoutDate) : "-"}`],
      ["Phone", `${supplierDetails?.Phone ? supplierDetails?.Phone : "-"}`, "Ergon Reference", `${ergonRef ? ergonRef : "-"}`],
      ["Ship To", { content: `${formData?.Ship ? formData?.Ship : "-"}`, colSpan: 3 }],
    ],
    styles: { fontSize: 10, cellPadding: 3, textAlign: "center" },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontStyle: 'normal', lineColor: [0, 0, 0], lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.1 },
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 88 },
      2: { cellWidth: 46 },
      3: { cellWidth: 88 },
    },
    didDrawPage: (data) => addFooterToPDF(pdf, pageWidth, pageHeight, data.pageNumber),
  });

  // Adjust startY for the inspection items table based on the last table's position
  const startY = pdf.lastAutoTable.finalY + 3;

  const itemRows = items.map((item, index) => [
    index + 1,
    item.Item_Name,
    item.Part_Number,
    item.UoM,
    item.Inquired_Qty,
    item.Remarks
  ]);

  // Inspection Items Table with Increased Cell Height
  pdf.autoTable({
    startY: startY,
    tableWidth: pageWidth - 30,  // Make table full width
    head: [["S.No", "Item Description", "Part Number", "UOM", "Qty.", "Remarks"]],
    body: itemRows,
    headStyles: { fillColor: [226, 230, 255], textColor: [0, 0, 0], fontStyle: 'bold', lineColor: [0, 0, 0], lineWidth: 0.1, halign: 'center', },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.1 },
    alternateRowStyles: { fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.1  },
    alternateRowStyles: { fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.1  },
    
    
    theme: 'grid',
    rowPageBreak: 'avoid',  // Avoid breaking rows across pages
    bodyStyles: { minCellHeight: 17, lineColor: [0, 0, 0], lineWidth: 0.25, halign: 'center', valign: 'center' }, // Increase the minimum cell height
    didDrawPage: (data) => addFooterToPDF(pdf, pageWidth, pageHeight, data.pageNumber),
  });

  
  // Check remaining space and add footer component
  let footerY = pdf.lastAutoTable.finalY + 35;  // Adjust based on your table size and position
  const remainingSpace = pageHeight - footerY;

  const footerHeight = 55;  // The estimated height of the "Prepared by" and "Terms and Conditions" section

  // If not enough space, add a new page
  if (remainingSpace < footerHeight) {
    pdf.addPage();
     addFooterToPDF(pdf, pageWidth, pageHeight, pdf.internal.getNumberOfPages()); // Add footer to the new page
    footerY = 35;  // Reset the footer Y position for the new page
  }

  // Add footer (Prepared by and Terms and Conditions)
  addPreparedByAndTerms(pdf, formData, footerY, pageWidth);

  

  // Generate PDF as Blob
  const pdfBlob = pdf.output("blob");
  return URL.createObjectURL(pdfBlob);
};

export const generatePDF = (formData, ergonRef, supplierDetails, rfqNo, items) => {
  // Create a new jsPDF instance in landscape mode
  const pdf = new jsPDF("l", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Add border around the logo and heading
  pdf.setLineWidth(0.25);
  pdf.rect(14, 10, pageWidth - 30, 30);  // Rect around the logo and heading

  // Add the logo and title
  const logoImg = new Image();
  logoImg.src = logo;  // Assuming 'logo' is a base64 or URL string
  pdf.addImage(logoImg, "PNG", 30, 15, 55, 20);
  
  pdf.setFontSize(21);
  pdf.text("REQUEST FOR QUOTATION", pageWidth / 2, 33, null, null, "left");



  // Metadata Table (same width as the form data table)
  pdf.autoTable({
    startY: 45,
    tableWidth: pageWidth - 20,
    head: [["Supplier Name", `${supplierDetails?.Supplier_Name ? supplierDetails?.Supplier_Name : "-"}`, "RFQ No", `${rfqNo ? rfqNo : "-"}` ]],
    body: [
      ["Tax Registration No", `${supplierDetails?.Tax_Reg_No ? supplierDetails?.Tax_Reg_No : "-"}`, "RFQ Date", `${formatDate(formData?.RFQDate ? formData?.RFQDate : "-")}`],
      ["Address", `${supplierDetails?.Address ? supplierDetails?.Address : "-"}`, "Expected Delivery Date", `${formData?.ExpDeliveryDate ? formData?.ExpDeliveryDate : "-"}`],
      ["Email", `${supplierDetails?.Email ? supplierDetails?.Email : "-"}`, "Closeout Date", `${formatDate(formData?.CloseoutDate ? formData?.CloseoutDate : "-")}`],
      ["Phone", `${supplierDetails?.Phone ? supplierDetails?.Phone : "-"}`, "Ergon Reference", `${ergonRef ? ergonRef : "-"}`],
      ["Ship To", { content: `${formData?.Ship ? formData?.Ship : "-"}`, colSpan: 3 }],
    ],
    styles: { fontSize: 10, cellPadding: 3, textAlign: "center" },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontStyle: 'normal', lineColor: [0, 0, 0], lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.1 },
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 88 },
      2: { cellWidth: 46 },
      3: { cellWidth: 88 },
    },
    didDrawPage: (data) => addFooterToPDF(pdf, pageWidth, pageHeight, data.pageNumber),
  });

  // Adjust startY for the inspection items table based on the last table's position
  const startY = pdf.lastAutoTable.finalY + 3;

  const itemRows = items.map((item, index) => [
    index + 1,
    item.Item_Name,
    item.Part_Number,
    item.UoM,
    item.Inquired_Qty,
    item.Remarks
  ]);

  // Inspection Items Table with Increased Cell Height
  pdf.autoTable({
    startY: startY,
    tableWidth: pageWidth - 30,  // Make table full width
    head: [["S.No", "Item Description", "Part Number", "UOM", "Qty.", "Remarks"]],
    body: itemRows,
    headStyles: { fillColor: [226, 230, 255], textColor: [0, 0, 0], fontStyle: 'bold', lineColor: [0, 0, 0], lineWidth: 0.1, halign: 'center', },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineColor: [0, 0, 0], lineWidth: 0.1 },
    alternateRowStyles: { fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.1  },
    alternateRowStyles: { fillColor: [255, 255, 255], lineColor: [0, 0, 0], lineWidth: 0.1  },
    
    
    theme: 'grid',
    rowPageBreak: 'avoid',  // Avoid breaking rows across pages
    bodyStyles: { minCellHeight: 17, lineColor: [0, 0, 0], lineWidth: 0.25, halign: 'center', valign: 'center' }, // Increase the minimum cell height
    didDrawPage: (data) => addFooterToPDF(pdf, pageWidth, pageHeight, data.pageNumber),
  });

  
  // Check remaining space and add footer component
  let footerY = pdf.lastAutoTable.finalY + 35;  // Adjust based on your table size and position
  const remainingSpace = pageHeight - footerY;

  const footerHeight = 55;  // The estimated height of the "Prepared by" and "Terms and Conditions" section

  // If not enough space, add a new page
  if (remainingSpace < footerHeight) {
    pdf.addPage();
     addFooterToPDF(pdf, pageWidth, pageHeight, pdf.internal.getNumberOfPages()); // Add footer to the new page
    footerY = 35;  // Reset the footer Y position for the new page
  }

  // Add footer (Prepared by and Terms and Conditions)
  addPreparedByAndTerms(pdf, formData, footerY, pageWidth);

  pdf.save("RFQ.pdf");
};

// Footer function to be called on every page
const addFooterToPDF = (pdf, pageWidth, pageHeight, pageNumber) => {
  const footerY = pageHeight - 20;
  
  pdf.setLineWidth(0.35);
  pdf.line(14, footerY + 5, pageWidth - 15, footerY + 5);

  pdf.setFontSize(8);
  pdf.text("TSD-PUR-FOR-007-23 Rev 01", 14, footerY + 10);
  pdf.text(`${formatDate(new Date().toLocaleDateString())}`, pageWidth / 2, footerY + 10, null, null, "center");
  pdf.text("This document is digitally generated and does not require a signature.", pageWidth / 2, footerY + 15, null, null, "center");
  pdf.text("Page No " + pageNumber, pageWidth - 15, footerY + 10, null, null, "right");
};

// Separate component for adding 'Prepared by' and 'Terms and Conditions'
const addPreparedByAndTerms = (pdf, formData, startY, pageWidth) => {
  pdf.setLineWidth(0.25);
  pdf.rect(14, startY - 18, pageWidth - 30, 55);

  pdf.setFontSize(12);
  pdf.text(`Prepared by `, 16, startY - 10);
  pdf.text(`Name: ${formData.RFQPrep}`, 16, startY - 4);

  pdf.line(14, startY , pageWidth - 16, startY );

  pdf.text(`Terms and Conditions`, 16, startY + 6)
  pdf.text("1.   Quotation must be submitted within a week.", 20, startY + 12)
  pdf.text("2.   Prices quoted should include all applicable taxes and charges. ", 20, startY + 17)
  pdf.text("3.   The item must meet the specifications as described in the RFQ document. ", 20, startY + 22)
  pdf.text("4.   Any deviations must be clearly stated in the quotation. ", 20, startY + 27)
  pdf.text("5.   All information provided in the RFQ and the responses will be treated as confidential. ", 20, startY + 32)

  
};



function SparesTradingInquiryRFQ() {

    const {id} = useParams();
    const [isEditing,setIsEditing] = useState(false);
    const [formData,setFormData] = useState({});
    const [originalData, setOriginalData] = useState({});
    const [status,setStatus] = useState("Incomplete");
    const [ergonRef,setErgonRef] = useState("");
    const [suppliers, setSuppliers] = useState([]);

    // Function to check if all fields are filled
    console.log("FORM DATA",formData)
    const checkFormStatus = () => {
    const fields = ["RFQDate", "RFQPrep", "ExpDeliveryDate", "CloseoutDate", "Ship"];
    // Check if all required fields have non-empty values
    const isComplete = fields.every(field => originalData[field] && originalData[field].trim() !== "" && originalData.CloseoutDate !== "Invalid Date" && originalData.RFQDate !== "Invalid Date");
    console.log("iscomplete",isComplete)
    setStatus(isComplete ? "Ready" : "Incomplete");
  };

  const statusColor = (status) => {
    switch(status) {
      case 'Ready' :
        return styles.ready;
      case 'Incomplete' :
        return styles.incomplete;
      default:
        return '';
    }
  }

    const handleOnChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleEdit = () => {
        setIsEditing(true)
    }

    const handleCancel = () => {
        setIsEditing(false)
        fetchData();
      setFormData(originalData);
    }

    const fetchData2 = async () => {
      try {
        const res2 = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparestrading_InquiryDetails?ID_No=${id}`
        );
        console.log("res2",res2)
        setErgonRef(res2.data.data[0].Inquiry_No)

      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
    console.log("ergon ref",ergonRef)

    const fetchData = async () => {
      try {
        const res = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/spares_inquiry_rfq_get/${id}`
        );
    
        if (res.status === 200) {
          const details = res.data[0];
          console.log("res",res)
    
          // Add initial data variables with consistent date formatting
          const initialData = {
            RFQDate: details.RFQ_Date,
            RFQPrep: details.RFQ_Prep,
            ExpDeliveryDate: details.Exp_Delivery_Date,
            CloseoutDate: details.Closeout_Date,
            Ship: details.Ship
          };
    
          setFormData(initialData);
          setOriginalData(initialData);


          checkFormStatus();
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    
    // Fetch suppliers and their RFQ numbers from the backend
  const fetchRFQDetails = async () => {
    try {
      const response = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/fetch_rfq_suppliers/${id}`);
      if (response.status === 200) {
        setSuppliers(response.data);
      } else {
        console.error("Error fetching suppliers");
      }
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }  
  };

  console.log("suppliers",suppliers)

    useEffect(() => {
      fetchData();
      fetchRFQDetails();
    },[id])

    useEffect(() => {
      
      fetchData2();
    },[id])

    useEffect(() => {
      // Check status whenever formData is updated
      checkFormStatus();
    }, [originalData]);

    const handleSave = async (e) => {
        if(e){
            e.preventDefault();
        }
        try{
          const res = await axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/sparesinquiry_rfq_post/${id}`,
        formData
        
      );
      console.log("formdata",formData)
      if(res.status==200){
        fetchData();
        console.log("resdata", res.data)
        setIsEditing(false)
      }
        } catch (error) {
          console.log("Error saving RFQ details ", error)
        }   
    }

    console.log("id",id)
    console.log("formdata 112", formData)

    const handleDates = (name, value) => {
        setFormData({
            ...formData,
            [name]: value ? value.format("YYYY-MM-DD") : null,
        });
    };


    // Function to fetch supplier details
const fetchSupplierDetails = async (supplierID) => {
  try {
    // Fetch supplier details
    const supplierRes = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_details/${supplierID}`);

    // Fetch items for the selected supplier
    const itemsRes = await axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/supplier_items/${id}/${supplierID}`);

    if (supplierRes.status === 200 && itemsRes.status === 200) {
      console.log("RES @@@", supplierRes)
      console.log("item @@@",itemsRes)

      const supplierDetails = supplierRes.data[0]
      const items = itemsRes.data;
      return {
        supplierDetails, items
      };
    } else {
      console.error("Error fetching supplier details");
    }
  } catch (error) {
    console.error("Error fetching supplier details:", error);
  }
};



    // Handle Preview button
    const handlePreview = async (e,supplierID, rfqNo) => {
      e.preventDefault();
      try {
        const {supplierDetails,items} = await fetchSupplierDetails(supplierID);
        console.log("Supplier Details Preview",supplierDetails)
        console.log("items preview",items)

        if (supplierDetails) {
          const pdfUrl = generatePDFBlob(formData, ergonRef, supplierDetails, rfqNo, items); // Pass supplierDetails to generate PDF
          window.open(pdfUrl, "_blank"); // Open PDF in a new tab
        }
      } catch (error) {
        console.error("Error generating preview:", error);
      }
    };

    // Handle Download button
const handleDownload = async (e,supplierID, rfqNo) => {
  e.preventDefault();
  try {
    const {supplierDetails, items} = await fetchSupplierDetails(supplierID);
    console.log("Supplier Details",supplierDetails)
    if (supplierDetails) {
      generatePDF(formData, ergonRef, supplierDetails, rfqNo, items); // Pass supplierDetails to generate PDF
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
    // mui styling for date picker
  const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiPickersBasePicker-pickerView": {
      backgroundColor: "#DADCE0",
    },
    "& .MuiOutlinedInput-root": {
      width: "280px",
      "&:hover": {
        "& fieldset": {
          borderColor: "1px solid #DADCE0",
        },
      },
      "&:not(.Mui-focused)": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          border: "1px solid #DADCE0",
          borderRadius: "8px",
        },
      },
    },
  }));

  return (
    <div>
      <TabsBarSparesInquiry activeTab="RFQ" />

      <div className={styles.mainContainer}>
        <div>
          <h4 className={styles.heading1}>Suppliers and RFQ</h4>
        </div>

        <div className={styles.rfqDetails}>
          <div className={styles.rfqHeading}>
            <h4 className={styles.heading2}>RFQ Details</h4>

            {isEditing ? (
              <div>
                <button className={styles.editBtn} onClick={() => handleSave()}>
                  Save
                </button>
                <button
                  className={styles.cancelBtn}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button className={styles.editBtn} onClick={() => handleEdit()}>
                Edit
              </button>
            )}
          </div>

          <div className={styles.splitHalf}>
            <div className={styles.leftHalf}>
              <div className={styles.formElement}>
                <label>RFQ Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="RFQDate"
                    value={
                      formData.RFQDate
                        ? dayjs(formData.RFQDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("RFQDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>RFQ Prepared By</label>
                <select
                  value={formData.RFQPrep}
                  name="RFQPrep"
                  onChange={handleOnChange}
                  className={isEditing ? null : styles.cursor}
                  disabled={!isEditing}
                  required
                >
                  <option value=""></option>
                  <option value="Sandhya">Sandhya</option>
                  <option value="Ramadas">Ramadas</option>
                  <option value="Midhula">Midhula</option>
                  <option value="Ajhar">Ajhar</option>
                  <option value="Irfan">Irfan</option>
                  <option value="Basil">Basil</option>
                  <option value="Haneef">Haneef</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className={styles.formElement}>
                <label>Expected Delivery Date</label>
                <select
                  value={formData.ExpDeliveryDate}
                  name="ExpDeliveryDate"
                  onChange={handleOnChange}
                  className={isEditing ? null : styles.cursor}
                  disabled={!isEditing}
                  required
                >
                  <option value=""></option>
                  <option value="within 1 weeks">within 1 week</option>
                  <option value="within 2 weeks">within 2 weeks</option>
                  <option value="within 3 weeks">within 3 weeks</option>
                  <option value="within 4 weeks">within 4 weeks</option>
                  <option value="within 5 weeks">within 5 weeks</option>
                  <option value="within 6 weeks">within 6 weeks</option>
                  <option value="within 7 weeks">within 7 weeks</option>
                  <option value="within 8 weeks">within 8 weeks</option>
                </select>
                <svg
                  style={{ position: "absolute", right: "15" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9207 8.18005H11.6907H6.08072C5.12072 8.18005 4.64073 9.34005 5.32073 10.0201L10.5007 15.2001C11.3307 16.0301 12.6807 16.0301 13.5107 15.2001L15.4807 13.2301L18.6907 10.0201C19.3607 9.34005 18.8807 8.18005 17.9207 8.18005Z"
                    fill="black"
                  />
                </svg>
              </div>

            </div>

            <div className={styles.rightHalf}>
            <div className={styles.formElement}>
                <label>Closeout Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    name="CloseoutDate"
                    value={
                      formData.CloseoutDate
                        ? dayjs(formData.CloseoutDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(value) => handleDates("CloseoutDate", value)}
                    slotProps={{ textField: { size: "small" } }}
                    format="DD/MM/YYYY"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                    className={isEditing ? null : styles.textbox1}
                    disabled={!isEditing}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.formElement}>
                <label>Ship To</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                <textarea
                  type="text"
                 value={formData.Ship}
                  name="Ship"
                  onChange={handleOnChange}
                  className={isEditing ? styles.textbox1b : styles.textbox1a}
                  disabled={!isEditing}
                />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.listing}>
        {suppliers.map((supplier,index) => (
            <div key={index} className={styles.list}>
            <div className={styles.leftList}>
                    <label className={styles.listName}>
                        {index +1}. {supplier.Supplier_Name}
                    </label>

                    <div className={`${styles.listStatus} ${statusColor(status)}`}>
                        {status}
                    </div>


                    <div className={styles.rfqID}>
                        RFQ : {supplier.RFQ_No}
                    </div>
                    </div>

                    <div className={styles.listBtn}>
                        <button className={styles.previewBtn} 
                        onClick={(e) => handlePreview(e,supplier.Supplier_ID, supplier.RFQ_No)}>
                            Preview
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={styles.svgIcon}
                            >
                                <path
                                d="M21.25 9.14999C18.94 5.51999 15.56 3.42999 12 3.42999C10.22 3.42999 8.49 3.94999 6.91 4.91999C5.33 5.89999 3.91 7.32999 2.75 9.14999C1.75 10.72 1.75 13.27 2.75 14.84C5.06 18.48 8.44 20.56 12 20.56C13.78 20.56 15.51 20.04 17.09 19.07C18.67 18.09 20.09 16.66 21.25 14.84C22.25 13.28 22.25 10.72 21.25 9.14999ZM12 16.04C9.76 16.04 7.96 14.23 7.96 12C7.96 9.76999 9.76 7.95999 12 7.95999C14.24 7.95999 16.04 9.76999 16.04 12C16.04 14.23 14.24 16.04 12 16.04Z"
                                fill="black"
                                />
                                <path
                                d="M12.0004 9.14001C10.4304 9.14001 9.15039 10.42 9.15039 12C9.15039 13.57 10.4304 14.85 12.0004 14.85C13.5704 14.85 14.8604 13.57 14.8604 12C14.8604 10.43 13.5704 9.14001 12.0004 9.14001Z"
                                fill="black"
                                />
                            </svg>
                        </button>

                        <button className={styles.DownloadBtn} 
                        onClick={(e) => handleDownload(e,supplier.Supplier_ID, supplier.RFQ_No)}>
                            Download PDF
                            <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.svgIcon}
                    >
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                        fill="#292D32"
                      />
                    </svg>
                        </button>
                    </div>
            </div>
            ))}

        </div>
      </div>
    </div>
  );
}

export default SparesTradingInquiryRFQ