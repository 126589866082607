import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./css/inquirylisting.module.css";
import { NavLink } from "react-router-dom";
import LoadingScreen from './LoadingScreen';

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.common["Content-Type"] = "application/json";

function WorkshopSparesInquiryListing() {
  const navigate = useNavigate();
  const [inquiryLists, setInquiryLists] = useState([]);
  const [checklistStatuses, setChecklistStatuses] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [filterContainer, setFilterContainer] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNav = () => {
    navigate("/workshopspares-inquiry-createnew");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterContainerToggle = () => {
    setFilterContainer(!filterContainer);
  };

  const handleStatusToggle = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
    if (categoryDropdownOpen) {
      setCategoryDropdownOpen(false);
    }
  };
  
  const handleCategoryToggle = () => {
    setCategoryDropdownOpen(!categoryDropdownOpen);
    if (statusDropdownOpen) {
      setStatusDropdownOpen(false);
    }
  };
  

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  useEffect(() => {
    const fetchInquiries = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/inquiry_details");
        const inquiries = response.data;
        setInquiryLists(inquiries);
        setFilteredInquiries(inquiries);

        // Fetch checklist status for each inquiry
        const statusRequests = inquiries.sort((a, b) => b.Inquiry_ID - a.Inquiry_ID).map((inquiry) =>
          axios.get(`http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/project_checklist_status/${inquiry.Inquiry_ID}`)
        );

        const statusResponses = await Promise.all(statusRequests);

        console.log("statusres",statusResponses)
        const statusMap = {};
        statusResponses.forEach((res, index) => {
          statusMap[inquiries[index].Inquiry_ID] = res.data[0].Item5;
        });

        setChecklistStatuses(statusMap);
      } catch (error) {
        console.error("Error fetching inquiries or statuses:", error);
      }
      setIsLoading(false);
    };

    fetchInquiries();
  }, []);

  console.log("status",checklistStatuses[150024])
  console.log("status2",checklistStatuses)

  const getStatus = (inquiry) => {
    const today = new Date();
    const expiryDate = new Date(inquiry.Inquiry_Expiry_Date);

     // Check for 'discarded' or 'rejected' status from checklist
     const checklistStatus = checklistStatuses[inquiry.Inquiry_ID];
     if (checklistStatus === 'Discarded' || checklistStatus === 'Rejected') return checklistStatus;

    // Open status (default)
    if (today < expiryDate) return 'open';

    // Pending status
    if (today > expiryDate && !inquiry.Quote_No && !inquiry.Status) return 'pending';

    // Accepted status
    if (inquiry.PO_No && inquiry.Quote_No) return 'accepted';

    // Quote Sent status
    if (inquiry.Quote_No) return 'quote sent';

    // Default to 'open' if none of the conditions match
    return 'open';
  };

  const statusColor = (status) => {
    switch(status) {
      case 'open' :
        return styles.open;
      case 'pending' :
        return styles.pending;
      case 'accepted' :
        return styles.accepted;
      case 'quote sent' :
        return styles.quote_sent;
      default:
        return styles.discard;
    }
  }

  const handleApplyFilters = () => {
    const filtered = inquiryLists.filter((inquiry) => {
      const inquiryStatus = getStatus(inquiry); // Get the computed status
      const inquiryCategory = inquiry.Category; // Use Category directly
  
      const statusMatch = selectedStatus ? inquiryStatus.toLowerCase() === selectedStatus.toLowerCase() : true;
      const categoryMatch = selectedCategory ? inquiryCategory === selectedCategory : true;
      
      return statusMatch && categoryMatch;
    });
  
    setFilteredInquiries(filtered);

    // Reset the selected filters
    setSelectedStatus("");
    setSelectedCategory("");
    
    setFilterContainer(false);
    setStatusDropdownOpen(false);
    setCategoryDropdownOpen(false);
  };
  

  const filteredSearchResults  = filteredInquiries
  .sort((a, b) => b.Inquiry_ID - a.Inquiry_ID) // Sort in decreasing order of Inquiry_ID
  .filter((inquiry) => {
    const inquiryId = inquiry?.Inquiry_ID ? inquiry.Inquiry_ID.toString() : ""; // check if Inquiry_ID exists
    const quoteNo = inquiry?.Quote_No ? inquiry.Quote_No.toString() : ""; // check if Quote_No exists
    const customerName = inquiry?.Customer_Name ? inquiry.Customer_Name.toLowerCase() : ""; // check if Customer_Name exists
    const inquiryNo = inquiry?.Inquiry_No ? inquiry.Inquiry_No.toString() : ""; // check if Inquiry_No exists

    return (
      searchTerm === "" ||
      inquiryId.includes(searchTerm) ||
      quoteNo.includes(searchTerm) ||
      customerName.includes(searchTerm.toLowerCase()) ||
      inquiryNo.includes(searchTerm)
    );
  });


  return (
    <div className={styles.listContainer}>
      <LoadingScreen isLoading={isLoading} />

      {/* top container starts */}
      <div className={styles.topContainer}>
        <div className={styles.top1}>
          <h3 className={styles.listHeading}>Inquiry</h3>

          <div className={styles.Searchbar}>
            <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder="Search by ID, Quote No, Customer Name, Inquiry No "
                onChange={handleSearchChange}
              />
              <svg
                className={styles.searchIconFilter}
                style={{ marginLeft: "5%" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                  fill="#0000008F"
                />
                <path
                  d="M22.0004 22.7499C21.8104 22.7499 21.6204 22.6799 21.4704 22.5299L19.4704 20.5299C19.1804 20.2399 19.1804 19.7599 19.4704 19.4699C19.7604 19.1799 20.2404 19.1799 20.5304 19.4699L22.5304 21.4699C22.8204 21.7599 22.8204 22.2399 22.5304 22.5299C22.3804 22.6799 22.1904 22.7499 22.0004 22.7499Z"
                  fill="#0000008F"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className={styles.top2}>
          <div className={styles.filterContainer}>
            <button className={styles.filterButton} onClick={handleFilterContainerToggle}>
              Filter
              <svg
                className={styles.svg1}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.491 1.66699H6.50768C3.47435 1.66699 1.66602 3.47533 1.66602 6.50866V13.4837C1.66602 16.5253 3.47435 18.3337 6.50768 18.3337H13.4827C16.516 18.3337 18.3243 16.5253 18.3243 13.492V6.50866C18.3327 3.47533 16.5243 1.66699 13.491 1.66699ZM11.1077 14.167H8.88268C8.54102 14.167 8.25768 13.8837 8.25768 13.542C8.25768 13.2003 8.54102 12.917 8.88268 12.917H11.1077C11.4493 12.917 11.7327 13.2003 11.7327 13.542C11.7327 13.8837 11.4577 14.167 11.1077 14.167ZM13.3327 10.6253H6.66602C6.32435 10.6253 6.04102 10.342 6.04102 10.0003C6.04102 9.65866 6.32435 9.37533 6.66602 9.37533H13.3327C13.6743 9.37533 13.9577 9.65866 13.9577 10.0003C13.9577 10.342 13.6743 10.6253 13.3327 10.6253ZM14.9993 7.08366H4.99935C4.65768 7.08366 4.37435 6.80033 4.37435 6.45866C4.37435 6.11699 4.65768 5.83366 4.99935 5.83366H14.9993C15.341 5.83366 15.6243 6.11699 15.6243 6.45866C15.6243 6.80033 15.341 7.08366 14.9993 7.08366Z"
                  fill="#292D32"
                ></path>
              </svg>
            </button>
            {filterContainer && (
              <div className={styles.optionContainerworkshop}>
                <div className={styles.dropdown}>
                <button onClick={handleStatusToggle} className={styles.dropdownButton}>
                    Status 
                    <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                  </button>
                  {statusDropdownOpen  && (
                    <div className={styles.itemsContainer1}>
                      {["Open", "Pending", "Quote sent", "Discarded", "Accepted", "Rejected"].map((status) => (
                        <label key={status} className={styles.optionsList}>
                          <input
                            type="radio"
                            name="status"
                            value={status}
                            checked={selectedStatus === status}
                            onChange={handleStatusChange}
                            className={styles.radio1}
                          />
                          {status}
                        </label>
                      ))}
                    </div>
                  )}
                </div>

                <div className={styles.dropdown}>
                  <button onClick={handleCategoryToggle} className={styles.dropdownButton}>
                    Category
                    <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.75 13.5L11.25 9L6.75 4.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                  </button>
                  {categoryDropdownOpen  && (
                    <div className={styles.itemsContainer2}>
                      {["Spares", "Service"].map((category) => (
                        <label key={category} className={styles.optionsList}>
                          <input
                            type="radio"
                            name="category"
                            value={category}
                            checked={selectedCategory === category}
                            onChange={handleCategoryChange}
                          />
                          {category}
                        </label>
                      ))}
                    </div>
                  )}
                </div>

                <div className={styles.applyContainer}>
                  <button onClick={handleApplyFilters} className={styles.applyBtn}>
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>

          <div>
            <button onClick={handleNav} className={styles.createNew}>
              Create New
              <svg
                style={{ marginLeft: "10px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-plus-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* top container ends */}

      {/* listing container starts */}
      <div className={styles.listingContainer}>
        {filteredSearchResults.length > 0 ? (
          filteredSearchResults.map((inquiry) => (
            <div key={inquiry.Inquiry_ID} className={styles.list}>
              <NavLink
                to={`/workshopspares-inquiry-details/${inquiry.Inquiry_ID}`}
                className={styles.nav}
              >
                <div className={styles.nav1}>
                  <div className={styles.navTop}>
                    <h6>
                      ID: <span>{inquiry.Inquiry_ID}</span>
                    </h6>
                    <h6>|</h6>
                    <h6>
                      <span>{inquiry.Category}</span>
                    </h6>
                    <h6>|</h6>
                    <h6>
                      Quote: <span>{inquiry.Quote_No}</span>
                    </h6>
                  </div>

                  <div className={styles.navBottom}>
                    <span style={{width: "150px", maxWidth: "150px"}}>{inquiry.Customer_Name}</span>

                    <span style={{ display: "flex", marginLeft: "20px", width: "200px", maxWidth: "200px" }}>
                      <span style={{ color: "#606060", whiteSpace: "nowrap" }}>
                        Customer Inquiry No :
                      </span>
                      {inquiry.Inquiry_No}
                    </span>

                    <span style={{display: "flex", width: "250px", maxWidth: "250px", marginLeft: "150px", }}>
                      <span style={{ color: "#606060", whiteSpace: "nowrap" }}>
                        Inquiry Date :
                      </span>
                      {inquiry.Inquiry_Date}
                    </span>

                    <span style={{width: "250px", maxWidth: "250px"}}>{inquiry.Rig_Name}</span>
                  </div>
                </div>
                <div className={`${styles.status} ${statusColor(getStatus(inquiry))}`}>{getStatus(inquiry)}</div>
              </NavLink>
            </div>
          ))
        ) : (
          <div className={styles.nolist}>No Inquires Available</div>
        )}
      </div>
    </div>
  );
}

export default WorkshopSparesInquiryListing;
