import React from "react";
import styles from "./css/MaterialProducedTabs.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function FinishedGoods() {
  const [itemsReceived, setItemsReceived] = useState([]);
  const [idarray, setidarray] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [projectList, setProjectList] = useState({});
  const [indetails, setindetails] = useState([]);
  const [workshop] = useState(11);
  const [editedRemarks, setEditedRemarks] = useState({});
  const [editedLocation, setEditedLocation] = useState({});
  const [editedWeight, setEditedWeight] = useState({});
  const [editedCoo, setEditedCoo] = useState({});
  const [editedHSCode, setEditedHSCode] = useState({});
  const [editedEquipmentTraceabilityNo, setEditedEquipmentTraceabilityNo] = useState({});
  const [changedItems, setChangedItems] = useState([]);
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "", 
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = async () => {
    try {
      const updatedItems = itemsReceived
        .filter((item) => changedItems.includes(item.S_No))
        .map((item) => ({
          ...item,
          Equipment_Traceability_Number:editedEquipmentTraceabilityNo[item.S_No]||item.Equipment_Traceability_Number,
          HS_Code: editedHSCode[item.S_No] || item.HS_Code,
          COO: editedCoo[item.S_No] || item.COO,
          Weight: editedWeight[item.S_No] || item.Weight,
          Location: editedLocation[item.S_No] || item.Location,
          Remarks: editedRemarks[item.S_No] || item.Remarks,
        }));

      await Promise.all(
        updatedItems.map((updatedItem) =>
          axios.put(
            `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_put2?S_No=${updatedItem.S_No}`,
            updatedItem
          )
        )
      );

      // Fetch the updated data from the server after the API call
      const res = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get2?Project_ID=${id}`
      );

      // Update the state with the new data
      setItemsReceived(res.data.data);
  
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemarksChange = (id, value) => {
    setEditedRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleLocationChange = (id, value) => {
    setEditedLocation((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleWeightChange = (id, value) => {
    setEditedWeight((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleCooChange = (id, value) => {
    setEditedCoo((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleHSCodeChange= (id, value) => {
    setEditedHSCode((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleEquipmentTraceabilityNoChange= (id, value) => {
    setEditedEquipmentTraceabilityNo((prevRemarks) => ({
      ...prevRemarks,
      [id]: value === "" ? null : value,
    }));
    if (!changedItems.includes(id)) {
      setChangedItems((prevItems) => [...prevItems, id]);
    }
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  };
  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  };
  
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }   
  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  useEffect(() => {
    async function fetchsmDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/incomingdelivery_details?ID_No=${id}`
        );
        setindetails(response.data.data);
        setidarray(response.data.data.map((item) => item.S_No));
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchsmDetails();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );

      const res1 = await axios.get(
        `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/itemsproduced_get2?Project_ID=${id}`
      );
      console.log("items received", res1.data.data);
      setItemsReceived(res1.data.data);
      const filter = res.data.data.filter((el) => el.ID_No == id);
      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PO_Date: filter[0]?.PODate,
        QuoteNo: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [idarray]);

  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl1}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}
        >
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        <div
          className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick15}>
          Production
        </div>

        {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
                <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>

        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}>
          Outsourcing
        </div>
        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}>
          Reports
        </div>
        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}>
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}>
          Items Received
        </div>
        <div
          className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}
        >
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}
        >
          WIP
        </div>
      </div>

      {!isEditing ? (
        // Non-editable display of data
        <div className={styles.maindiv}>
          <div className={styles.buttonsdiv}>
            <button onClick={handleEditClick}>
              Edit{" "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "-5px", marginLeft: "5px" }}
              >
                <path
                  d="M0 11.1483V14H2.85171L11.2624 5.58935L8.41065 2.73764L0 11.1483ZM14 2.85171L11.1483 0L9.22433 1.93156L12.076 4.78327L14 2.85171Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Ergon Traceability No</th>
                  <th>Equipment Traceability No</th>
                  <th>UoM</th>
                  <th>Qty</th>
                 
                  <th>HS Code</th>
                  <th>COO</th>
                  <th>Weight</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.map((item,index) => (
                  <tr key={item.S_No}>
                    <td>{index+1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.Part_Number}</td>
                    <td>{item.Part_Traceability_Number}</td>
                    <td>{item.Equipment_Traceability_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{item.Qty_Produced}</td>
          
                    <td>{item.HS_Code}</td>
                    <td>{item.COO}</td>
                    <td>{item.Weight}</td>
                    <td>{item.Location}</td>
                    <td>{item.Remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Editable data state
        <div className={styles.maindiv}>
          <div className={styles.buttonsdiv}>
            <button
              onClick={handleSaveClick}
              style={{ marginRight: "10px" }}
            >
              Save{" "}
            </button>

            <button
              onClick={handleCancelClick}
              style={{
                background: "white",
                ":hover": { background: "#F5F5F5" },
                border: "1px solid #5B6BE1",
                color: "#5B6BE1",
                justifyContent: "center",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              Cancel{" "}
            </button>
          </div>

          <div className={styles.itemstablecontainer}>
            <table className={styles.itemstable}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Item Name</th>
                  <th>Part Number</th>
                  <th>Ergon Traceability No</th>
                  <th>UoM</th>
                  <th>Qty</th>
                  <th>Equipment Traceability No</th>
                  
                  <th>HS Code</th>
                  <th>COO</th>
                  <th>Weight</th>
                  <th>Location</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {itemsReceived.map((item,index) => (
                  <tr key={item.S_No}>
                    <td>{index+1}</td>
                    <td>{item.Item_Name}</td>
                    <td>{item.Part_Number}</td>
                    <td>{item.Ergon_Traceability_Number}</td>
                    <td>{item.UoM}</td>
                    <td>{item.Qty_Produced}</td>
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "6vw" }}
                        type="text"
                        value={editedEquipmentTraceabilityNo[item.S_No] || item.Equipment_Traceability_Number}
                        onChange={(e) =>
                          handleEquipmentTraceabilityNoChange(item.S_No, e.target.value)
                        }
                      />
                    </td>{" "}
                    
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "6vw" }}
                        type="text"
                        value={editedHSCode[item.S_No] || item.HS_Code}
                        onChange={(e) =>
                          handleHSCodeChange(item.S_No, e.target.value)
                        }
                      />
                    </td>{" "}
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "4vw" }}
                        type="text"
                        value={editedCoo[item.S_No] || item.COO}
                        onChange={(e) =>
                          handleCooChange(item.S_No, e.target.value)
                        }
                      />
                    </td>{" "}
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "4vw" }}
                        type="text"
                        value={editedWeight[item.S_No] || item.Weight}
                        onChange={(e) =>
                          handleWeightChange(item.S_No, e.target.value)
                        }
                      />
                    </td>{" "}
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "6vw" }}
                        type="text"
                        value={editedLocation[item.S_No] || item.Location}
                        onChange={(e) =>
                          handleLocationChange(item.S_No, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        className={styles.textbox1}
                        style={{ maxWidth: "10vw" }}
                        type="text"
                        value={editedRemarks[item.S_No] || item.Remarks}
                        onChange={(e) =>
                          handleRemarksChange(item.S_No, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinishedGoods;
