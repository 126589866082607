import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './css/tabs_bar_spares_inquiry.module.css';

const TabsBarSparesInquiry = ({ activeTab }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDivClick = (tab, path) => {
    navigate(`${path}${id}`);
  };

  return (
    <div className={styles.sections1}>
      <div
        className={activeTab === 'Inquiry Checklist' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Inquiry Checklist', '/workshop-spares-inquiry-checklist/')}
      >
        Inquiry Checklist
      </div>
      <div
        className={activeTab === 'Inquiry Details' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Inquiry Details', '/workshopspares-inquiry-details/')}
      >
        Inquiry Details
      </div>
      <div
        className={activeTab === 'Item Details' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Item Details', '/workshop-spares-inquiry-item-details/')}
      >
        Item Details
      </div>
      <div
        className={activeTab === 'RFQ' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('RFQ', '/workshop-spares-inquiry-rfq/')}
      >
        RFQ
      </div>
      <div
        className={activeTab === 'Vendor Quotes' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Vendor Quotes', '/workshop-spares-inquiry-vendor-quotes/')}
      >
        Vendor Quotes
      </div>
      <div
        className={activeTab === 'Customer Quote' ? styles.DivTabs1 : styles.DivTabs2}
        onClick={() => handleDivClick('Customer Quote', '/workshop-spares-inquiry-customer-quote/')}
      >
        Customer Quote
      </div>
    </div>
  );
};

export default TabsBarSparesInquiry;
