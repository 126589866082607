import React from "react";
import styles from "./css/production.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material";
const token = localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function ProductionListing() {
  const { id } = useParams();   //Project ID taken from url
  const [workshop, setWorkshop] = useState(15);   //to set the Production tab in Purple, in the navigation bar
  const navigate = useNavigate();
  const [refresh, setrefresh] = useState(false);
  const [projectList, setProjectList] = useState({});
  const [prodDetails, setProdDetails] = useState([]);   //array which holds all the production data to be displayed on the screen
  const [consumedDetails, setConsumedDetails] = useState([]);   //array which holds the material_consumed data for this project
  const [openModal2, setOpenModal2] = useState(false);   //state to open and close the modal on clicking "Create New Production"
  const handleDivClick = () => {
    navigate(`/project-checklist-workshop/${id}`);
  };

  const handleDivClick1 = () => {
    navigate(`/project-details-workshop/${id}`);
  };
  const handleDivClick2 = () => {
    navigate(`/timesheet-workshop/${id}`);
  };

  const handleDivClick3 = () => {
    navigate(`/incoming-deliveries-workshop/${id}`);
  };

  const handleDivClick4 = () => {
    navigate(`/outgoing-deliveries-workshop/${id}`);
  };

  const handleDivClick5 = () => {
    navigate(`/outsourcing-workshop/${id}`);
  };

  const handleDivClick6 = () => {
    navigate(`/reports-workshop/${id}`);
  };

  const handleDivClick7 = () => {
    navigate(`/inspections-workshop/${id}`);
  };
  const handleDivClick8 = () => {
    navigate(`/items-received-workshop/${id}`);
  };
  const handleDivClick9 = () => {
    navigate(`/finished-goods-workshop/${id}`);
  };
  const handleDivClick10 = () => {
    navigate(`/team-details-workshop/${id}`);
  };
  const handleDivClick12 = () => {
    navigate(`/inventory-used-workshop/${id}`);
  }

  const handleDivClick13 = () => {
    navigate(`/purchases-workshop/${id}`);
  }
  const handleDivClick14 = () => {
    navigate(`/project-timeline-workshop/${id}`);
  }
  const handleDivClick15 = () => {
    navigate(`/production-workshop/${id}`)
  }
  
  const handleDivClick16 = () => {
    navigate(`/Wip-workshop/${id}`);
  };
  const [formdata1, setFormData1] = useState({
    salesOrderNo: "",

    PONO: "",
    PODays: "",
    PODate: "",
    QuoteNO: "",
    QuoteDate: "",
    CostingID: "",
    ProjectName: "",
    Scope: "",
    EstimatedDateCommencement: "",
    CustomerName: "",
    FirstArrival: "",
    TargetDaysForCompletion: "",
    TargetManhours: "",
    opsEngineer: "",
    quantity: "",
    projectDescription: "",
    currentStatus: "",
    Design: "",
    scopeDescription: "",
    DmccFze: "",
    FzeDmccPo: "",
  });
  const handleDivClick17 = () => {
    navigate(`/dispatch/${id}`);
  };

  useEffect(() => {
    const fetchProject = async () => {
      const res = await axios.get(
        "http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/workshop_project_details"
      );
      const filter = res.data.data.filter((el) => el.ID_No == id);

      setFormData1({
        ...formdata1,
        salesOrderNo: filter[0]?.Sales_Order_No?.toString(),
        PONO: filter[0]?.PO_No?.toString(),
        PODate: filter[0]?.PO_Date,
        QuoteNO: filter[0]?.Quote_No,
        QuoteDate: filter[0]?.Quote_Date,
        CostingID: filter[0]?.Costing_ID,
        ProjectName: filter[0]?.Project_Name,
        Scope: filter[0]?.Scope,
        projectDescription: filter[0]?.Project_Description,
        quantity: filter[0]?.Quantity,
        CustomerName: filter[0]?.Customer_Name,
        FirstArrival: filter[0]?.First_Arrival,
        TargetDaysForCompletion: filter[0]?.Target_Days_For_Completion,
        opsEngineer: filter[0]?.Ops_Engineer,
        TargetManhours: filter[0]?.Target_Manhours,
        currentStatus: filter[0]?.Current_Status,
        scopeDescription: filter[0]?.Scope_Description,
        EstimatedDateCommencement: filter[0]?.Estimated_Date_Of_Commencement,
        Design: filter[0]?.Design,
        DmccFze: filter[0]?.Dmcc_fze,
        FzeDmccPo: filter[0]?.Fze_dmcc_po,
      });
      console.log(filter);
      console.log(id);
      setProjectList(filter[0]);
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    async function fetchProdDetails() {
      try {
        const response = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/production_listing_details?Project_ID=${id}`
        );
        setProdDetails(response.data.data);
        const consumedRes = await axios.get(
          `http://ec2-13-200-53-206.ap-south-1.compute.amazonaws.com:8002/api/data/production_consumption_details?Project_ID=${id}`
        );
        setConsumedDetails(consumedRes.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchProdDetails();
  }, [refresh]);


  function formatDate(inputDate) {
    if (inputDate === null || inputDate === "") {
      return "-";
    }

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleCreateNewClick = () => {
    navigate(`/production-create-new-workshop/${id}`);
  }

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  }

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  }

  const handleProductionDivClick = (e, production_id) => {
    e.preventDefault();
    navigate(`/production_update/${id}/${production_id}`);
  }


  return (
    <div>
      <div className={styles.topdiv}>
        <label className={styles.aryl}>
          Project Name: {formdata1.ProjectName}
        </label>
        <label className={styles.aryl}>Project Scope:{formdata1.Scope}</label>
        <label className={styles.aryl}>
          Project Description:{formdata1.projectDescription}
        </label>
        <label className={styles.aryl}>Quantity:{formdata1.quantity}</label>
        <label className={styles.aryl}>
          Target Days:{formdata1.TargetDaysForCompletion}
        </label>
        <label className={styles.aryl}>
          Target Manhours:{formdata1.TargetManhours}
        </label>
      </div>
      <div className={styles.aryTabs}>
        <div
          className={workshop === 1 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick}>
          Project Checklist
        </div>
        <div
          className={workshop === 2 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick1}>
          Project Details
        </div>
        <div
          className={workshop === 10 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick10}
        >
          Team Details
        </div>
        <div
          className={workshop === 12 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick12}>
          Inventory Used
        </div>
        <div
          className={workshop === 13 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick13}>
          Purchases
        </div>
        <div
          className={workshop === 3 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick2}>
          Timesheet
        </div>
        <div
          className={workshop === 4 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick3}>
          Deliveries
        </div>
        {/* <div
          className={workshop === 5 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick4}>
          Outgoing Deliveries
        </div> */}
        <div
          className={workshop === 15 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick15}>
          Production
        </div>
        <div
          className={workshop === 14 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick14}>
          Timeline
        </div>
        <div
          className={workshop === 17 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick17}>
        Dispatch
        </div>
        <div
          className={workshop === 6 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick5}>
          Outsourcing
        </div>
        <div
          className={workshop === 7 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick6}>
          Reports
        </div>
        <div
          className={workshop === 8 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick7}>
          Inspections
        </div>
        <div
          className={workshop === 9 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick8}>
          Items Received
        </div>
        <div
          className={workshop === 11 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick9}
        >
          Finished goods
        </div>
        <div
          className={workshop === 16 ? styles.aryTabs1 : styles.aryTabs2}
          onClick={handleDivClick16}
        >
          WIP
        </div>
      </div>

      <div className={styles.maindiv}>
        <div className={styles.buttonContainer}>
          <button
            variant="contained"
            className={styles["btn-Createnew"]}
            onClick={(formdata1.Scope === "Assembly" || formdata1.Scope === "D&I") ? handleOpenModal2 : handleCreateNewClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 20C10.5 20.3978 10.658 20.7794 10.9393 21.0607C11.2206 21.342 11.6022 21.5 12 21.5C12.3978 21.5 12.7794 21.342 13.0607 21.0607C13.342 20.7794 13.5 20.3978 13.5 20V13.5H20C20.3978 13.5 20.7794 13.342 21.0607 13.0607C21.342 12.7794 21.5 12.3978 21.5 12C21.5 11.6022 21.342 11.2206 21.0607 10.9393C20.7794 10.658 20.3978 10.5 20 10.5H13.5V4C13.5 3.60218 13.342 3.22064 13.0607 2.93934C12.7794 2.65804 12.3978 2.5 12 2.5C11.6022 2.5 11.2206 2.65804 10.9393 2.93934C10.658 3.22064 10.5 3.60218 10.5 4V10.5H4C3.60218 10.5 3.22064 10.658 2.93934 10.9393C2.65804 11.2206 2.5 11.6022 2.5 12C2.5 12.3978 2.65804 12.7794 2.93934 13.0607C3.22064 13.342 3.60218 13.5 4 13.5H10.5V20Z" fill="white" />
              <defs>
                <rect
                  width="20"
                  height="20"
                  fill="white" />
              </defs>
            </svg>
            {' '}Create New Production
          </button>
        </div>
        {prodDetails.length === 0 ? (
          <h4 style={{ textAlign: "center", fontWeight: "700", marginTop: "15px", color: "#567191", marginRight: "50%" }}>No result found</h4>
        ) : (
          prodDetails
            .slice() // Create a shallow copy of the array
            .sort((a, b) => b.Production_ID - a.Production_ID) // Sort in descending order based on the S_No
            .map((el, index) => {
              const hasCSP = consumedDetails.some(cd => cd.Production_ID === el.Production_ID && cd.Consumption_Type === "CSP");
              const hasInventory = consumedDetails.some(cd => cd.Production_ID === el.Production_ID && cd.Consumption_Type === "Inventory");
              return (
                <div key={index} className={styles.projectList} onClick={(e) => handleProductionDivClick(e, el.Production_ID)}>
                  <div className={styles.textContainer}>
                    {`ID: ${el.Production_ID || '-'} \u00a0|\u00a0 
                      Target: ${el.Production_Type || '-'} \u00a0\u00a0|\u00a0 
                      Production Date: ${formatDate(el.Production_Date) || '-'} \u00a0|\u00a0 
                      Inventory used: ${hasInventory ? 'Yes' : 'No'} \u00a0|\u00a0 
                      CSP used: ${hasCSP ? 'Yes' : 'No'}
                    `}
                  </div>
                </div>
              );
            })
        )}
        <Modal
          open={openModal2}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            backdropFilter: "blur(4px)",
          }}
        >
          <Fade in={openModal2}>
            <Box
              sx={{
                width: "600px",
                height: "300px",
                p: 3,
                backgroundColor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "5px 4px 20px 0px rgba(0, 0, 0, 0.2)",
                borderRadius: "28px",
                border: "1px solid #5b6be1", // Set the border color
              }}
            >
              <div
                style={{
                  marginBottom: "2vh",
                  display: "flex",
                  justifyContent: "space-between", // Changed to space-between to push the last item to the right
                  alignItems: "center",
                  // border: "1px solid #5b6be1", // Set the border color
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "230px",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontFamily: "'Open Sans', sans-serif", // Ensure the font is available or imported
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      color: "#000000",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      fill="#FFC107"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 160 150"
                    >
                      <g>
                        <path
                          d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5
C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5
s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4
l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"
                        />
                      </g>
                    </svg>
                    Alert
                  </Typography>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleCloseModal2}
                  viewBox="0 0 25 25"
                  style={{
                    width: "23px", // Smaller size
                    height: "23px", // Smaller size
                    cursor: "pointer"
                  }}
                  id="x-circle"
                >
                  <circle
                    cx="12.5"
                    cy="12.5"
                    r="10"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></circle>
                  <line
                    x1="8"
                    x2="17"
                    y1="8"
                    y2="17"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                  <line
                    x1="8"
                    x2="17"
                    y1="17"
                    y2="8"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></line>
                </svg>
              </div>
              <div
                style={{
                  paddingLeft: "1.5%",
                  marginBottom: "3vh",
                  color: "#000000",
                }}
              >
                <h2 style={{ fontSize: "1.3rem", marginLeft: "30px" }}>
                  Since the scope of this project is{" "}
                  <span style={{ color: "#5B6BE1" }}>
                    {formdata1.Scope},  <br></br>
                    {formdata1.Scope === "Assembly" ? "assembly" : formdata1.Scope === "D&I" ? "dis-assembly" : ""}
                  </span>{" "}
                  production module will start.<br></br> Material produced will be
                  visible under{" "}
                  <span style={{ color: "#5B6BE1" }}>
                    {formdata1.Scope === "Assembly" ? "FG" : formdata1.Scope === "D&I" ? "WIP" : ""}
                  </span>{" "}
                  tab.
                  <br></br>
                  <br></br>
                  {/* <br></br> */}

                  Click on <span style={{ color: "#5B6BE1" }}>Confirm</span> to
                  Proceed:
                  <br></br>
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Keeps children centered horizontally
                  alignItems: "center", // Also keeps children centered horizontally, redundant but ensures centering if contents are wrapped or modified
                  // marginBottom: "4vh", // Needed for absolute positioning of the child
                  // Ensures the div occupies the full viewport height or adjust accordingly to your layout
                }}
              >
                <div className={styles.editAndSaveButtons}>
                  <button
                    variant="contained"
                    onClick={handleCreateNewClick}
                    className={styles.saveButton}
                  >
                    Confirm
                  </button>
                  <button
                    variant="outlined"
                    onClick={handleCloseModal2}
                    className={styles.cancelBtn}
                    style={{ marginBottom: "23px" }}
                  >
                    Cancel

                  </button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  )
}

export default ProductionListing
